import React, { useState, useRef, useEffect } from "react";
import Transition from "../../utils/Transition.js";

function FilterButton({ selectedPercentiles, setSelectedPercentiles }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = id => {
    const changeSelected = e => {
      const newArr = selectedPercentiles.includes(id) ? selectedPercentiles.filter(item => item !== id) : [...selectedPercentiles, id]
      setSelectedPercentiles(newArr)
    }
    return changeSelected
  }

  const options = [
    {
      id: 0,
      percentile: "Bottom 10%",
    },
    {
      id: 1,
      percentile: "20%",
    },
    {
      id: 2,
      percentile: "30%",
    },
    {
      id: 3,
      percentile: "40%",
    },
    {
      id: 4,
      percentile: "50%",
    },
    {
      id: 5,
      percentile: "60%",
    },
    {
      id: 6,
      percentile: "70%",
    },
    {
      id: 7,
      percentile: "80%",
    },
    {
      id: 8,
      percentile: "90%",
    },
    {
      id: 9,
      percentile: "95%",
    },
    {
      id: 10,
      percentile: "Top 5%",
    },
  ];



  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex mr-2 mb-2 md:mb-0">
      <button
        ref={trigger}
        className="btn justify-between min-w-44 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
        aria-label="Select percentiles"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center">
          <span>Percentiles</span>
        </span>
        <svg
          className="shrink-0 ml-1 fill-current text-gray-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>

      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full right-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {/* <Transition
        show={dropdownOpen}
        tag="div"
        className="origin-top-right z-10 absolute top-full left-0 right-auto md:left-auto md:right-0 min-w-56 bg-white border border-gray-200 pt-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      > */}
        <div ref={dropdown}>
          <div className="text-xs font-semibold text-gray-400 pt-1.5 pb-2 px-4">
            Filter
          </div>
          <ul className="mb-4">
            {options.map(item => {
              return (
                <li key={item.id} className="py-1 px-3" >
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={selectedPercentiles.includes(item.id)}
                      onChange={handleChange(item.id)} />
                    <span className="text-sm font-medium ml-2">
                      {item.percentile}
                    </span>
                  </label>
                </li>
              )
            })}
          </ul>
          <div className="py-2 px-3 border-t border-gray-200 bg-gray-50">
            <ul className="flex items-center justify-between">
              <li>
                <button onClick={() => setSelectedPercentiles([])} className="btn-xs bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600">
                  Clear
                </button>
              </li>
              <li>
                <button
                  className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={() => setDropdownOpen(false)}
                  onBlur={() => setDropdownOpen(false)}
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Transition >
    </div >
  );
}

export default FilterButton;
