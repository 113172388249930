import img1 from "../assets/images/icons/icon-1.jpg";
import img2 from "../assets/images/icons/icon-2.jpg";
import img3 from "../assets/images/icons/icon-3.jpg";
import img4 from "../assets/images/icons/icon-4.jpg";
import img5 from "../assets/images/icons/icon-5.jpg";
import img6 from "../assets/images/icons/icon-6.jpg";
import img7 from "../assets/images/icons/icon-7.jpg";
import img8 from "../assets/images/icons/icon-8.jpg";
import img9 from "../assets/images/icons/icon-9.jpg";
import img10 from "../assets/images/icons/icon-10.jpg";
import img11 from "../assets/images/icons/icon-11.jpg";
import img12 from "../assets/images/icons/icon-12.jpg";
import img13 from "../assets/images/icons/icon-13.jpg";
import img14 from "../assets/images/icons/icon-14.png";
import img15 from "../assets/images/icons/icon-15.jpg";


export const LogoCarouselData = [
    {
        id:1,
        imgSrc : img1
    },
    {
        id:2,
        imgSrc : img2
    },
    {
        id:3,
        imgSrc : img3
    },
    {
        id:4,
        imgSrc : img4
    },
    {
        id:5,
        imgSrc : img5
    },
    {
        id:6,
        imgSrc : img6
    },
    {
        id:7,
        imgSrc : img7
    },
    {
        id:8,
        imgSrc : img8
    },
    {
        id:9,
        imgSrc : img9
    },
    {
        id:10,
        imgSrc : img10
    },
    {
        id:11,
        imgSrc : img11
    },
    {
        id:12,
        imgSrc : img12
    },
    {
        id:13,
        imgSrc : img13
    },
    {
        id:14,
        imgSrc : img14
    },
    {
        id:15,
        imgSrc : img15
    }
]