import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SidebarGroupItems = ({ parentUrlName, names, open }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
      <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
        {names.map((i) => {
          const linkedPath = `/${parentUrlName}/${i
            .toLowerCase()
            .replaceAll(" ", "-")
            .replaceAll(":", "")
            .replaceAll("other-worlds", "other")}`;

          const isCurrentPath = pathname === linkedPath;
          return (
            <li key={i} className="mb-1 last:mb-0">
              <NavLink
                exact
                to={linkedPath}
                className={`block transition duration-150 truncate  ${isCurrentPath ? 'text-indigo-500 hover:text-indigo-500' : 'text-gray-400 hover:text-gray-200'}` } // 
              >
                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                  {i}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarGroupItems;
