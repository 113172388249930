import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
//import DuneEmbed from "../partials/DuneEmbed";
import DuneAllWorldsEmbed from "../partials/DuneAllWorldsEmbed";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import ValueBox from "../charts/ValueBox";
import { topTickLabelProps } from "@visx/axis/lib/axis/AxisTop";

function AllWorlds({ data, type }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabOptions = [
    {
      id: 0,
      tab: "Today's Top Transactions",
    },
    {
      id: 1,
      tab: "Market Cap",
    },
    {
      id: 2,
      tab: "Cumulative Sales Value",
    },
    {
      id: 3,
      tab: "Avg Price by Month",
    },
    {
      id: 4,
      tab: "Avg Price by Day",
    },
    {
      id: 5,
      tab: "Ownership",
    },
    {
      id: 6,
      tab: "Sales Value",
    },
  ];

  const tabMap = {
    0: "transactions",
    1: "worldRankings",
    2: "cumulativeSales",
    3: "pricePerMonth",
    4: "pricePerDay",
    5: "ownership",
    6: "value",
  };

  const widths = {
    transactions: 12,
    worldRankings: 12,
    cumulativeSales: 2,
    pricePerMonth: 4,
    pricePerDay: 4,
    ownership: 4,
    value: 4,
  };

  const componentTypeMap = {
    bar: BarChart,
    line: LineChart,
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* tabs */}
            <div className="relative mb-8">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-8">
                {tabOptions.map((tab) => {
                  return (
                    <button
                      key={tab.id}
                      className="mr-6 first:pl-8 last:mr-0"
                      onClick={() => {
                        setSelectedTab(tab.id);
                      }}
                    >
                      <div
                        className={`block pb-2 font-medium whitespace-nowrap ${
                          tab.id === selectedTab
                            ? "text-indigo-500 border-indigo-500 border-b-2"
                            : "text-slate-600"
                        }`}
                      >
                        {tab.tab}
                      </div>
                    </button>
                  );
                })}
              </ul>
            </div>
            <div className="grid grid-cols-12 gap-3 items-center">
              {
                /* Dune embeds */
                data[tabMap[selectedTab]].map((i, idx) => {
                  const width = widths[tabMap[selectedTab]];
                  if (i.isMmContent) {
                    console.log("here");
                    const height = width === 2 ? "200" : "350";
                    const { title, arg, type } = i;
                    const ChartComponent = componentTypeMap[type];
                    const { subtitle } = i;

                    return (
                      <div
                        key={arg + title + subtitle}
                        className={`flex flex-col col-span-full sm:col-span-${width} bg-white shadow-lg rounded-sm border border-gray-200`}
                        // height
                        style={{ height: height + "px" }}
                      >
                        <header className="px-2 py-2 ">
                          <h2
                            className="font-semibold text-sm text-gray-800"
                            style={{
                              fontFamily: "IBM Plex Sans",
                              fontWeight: "500",
                              fontSize: 14,
                              color: "black",
                              textAnchor: "middle",
                            }}
                          >
                            {title}
                          </h2>
                          <div>
                            <p
                              className="italic text-black font-medium font text-xs"
                              style={{
                                fontFamily: "IBM Plex Sans",
                                fontSize: 14,
                                color: "black",
                                textAnchor: "middle",
                              }}
                            >
                              {subtitle}
                            </p>
                          </div>
                        </header>

                        {/* bunun disinda div olmali stretch results diye class var dune'da */}
                        {selectedTab === 2 ? (
                          <ValueBox arg={arg} isPortals={i.isPortals} />
                        ) : (
                          <ParentSize>
                            {({ width, height }) => (
                              <ChartComponent
                                width={width}
                                height={height}
                                arg={arg}
                                isPortals={i.isPortals}
                              />
                            )}
                          </ParentSize>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <DuneAllWorldsEmbed
                        key={tabMap[selectedTab] + idx}
                        title={i.title}
                        subtitle={i.subtitle}
                        isTable={i.is_table}
                        src={i.embed_url}
                        width={width}
                      />
                    );
                  }
                })
              }
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AllWorlds;
