const DCLHeatmapCustomTooltip = ({ cell }, dimLen, isAvgTab, sceneTitle) => {
  if (cell.color === "#1E1C32") return null;
  const y = cell.serieId;
  const x = cell.data.x;

  const sectorLen = 1; //408 / dimLen;

  const getRangeX = (value) => {
    //??? not sure if these are correct
    const start = value - 151;
    const end = value - 151;

    return start === end ? start : `${start} to ${end}`;
  };

  const getRangeY = (value) => {
    //??? not sure if these are correct
    const start = value - 151;
    const end = value - 151;

    return start === end ? start : `${start} to ${end}`;
  };

  return (
    cell.label && (
      <div className="bg-white rounded shadow py-2 px-3 flex flex-col">
        <div className="mb-1">
          <span className="font-bold">
            {isAvgTab ? "Avg Online Users: " : "Transaction Count: "}
          </span>
          <span className="font-normal">{cell.label}</span>
        </div>
        <div>
          <span className="font-bold text-sm">x: </span>
          <span className="font-normal text-sm">{getRangeX(x)}</span>
        </div>
        <div>
          <span className="font-bold text-sm">y: </span>
          <span className="font-normal text-sm">{getRangeY(y)}</span>
        </div>
        <div>
          <span className="font-bold text-sm">Scene Title: </span>
          <span className="font-normal text-sm">{sceneTitle}</span>
        </div>
      </div>
    )
  );
};

export default DCLHeatmapCustomTooltip;

/* 
      <span style="display: block; width: 12px; height: 12px; background: rgb(248, 140, 82); margin-right: 7px;">
      </span>

    <div style="background: white; color: inherit; font-size: inherit; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px; padding: 5px 9px;">
    <div style="white-space: pre; display: flex; align-items: center;">
    </div>
    </div>

    */
