import React from "react";
import { Opportunity } from "./Components/Opportunity";
import { Slider } from "./Components/Carousel";
import ScrollLogo from "./Components/logoScroll";
import { Rotate } from "./Components/Rotate";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";

export const Home = () => {
  return (
    <div className="home">
      <Header />
      <div className="container">
        <div className="main-heading-container">
          <h1>
            The home of <br /> metaverse analytics
          </h1>
          <p>
            The metaverse data & insights you need to move confidently through
            the next digital frontier
          </p>
          <button>
            <a
              className="text-none margin-top"
              href="https://www.patreon.com/metametriks"
            >
              Subscribe to Pro today for $199
            </a>
          </button>
          <div className="flex-container-anchor">
            <a className="underline" href="https://youtu.be/sIEj67Ax4AY">
              Watch our video to see the benefits of a Pro subscription
            </a>
            <a
              className="underline margin-top-link"
              href="https://bit.ly/3mCYncz"
            >
              or check out our public dashboards
            </a>
          </div>
        </div>

        <Slider />

        <ScrollLogo />

        <div className="opportunity-cont">
          <Opportunity />
          <Rotate />
        </div>
      </div>
      <Footer />
    </div>
  );
};
