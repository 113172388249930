import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Hamburger from "../assets/images/hamburger.png";
import Logo from "../assets/images/Logo.png";

export const Header = () => {
  return (
    <>
      <div className="logo-wrapper">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <div className="menu-items">
          <div className="mobile-hide">
            <button className="contact-us">
              <a
                className="text-none margin-top header-contact-btn"
                href="mailto:hi@metametriks.com"
              >
                Contact us
              </a>
            </button>

            <button>
              <NavLink exact to="/login">
                Log in
              </NavLink>
            </button>
          </div>

          <div className="mobile-show">
            <img src={Hamburger} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};
