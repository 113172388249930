import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DCLHeatmap from "../charts/DCLHeatmap";
import FilterButton from "../partials/actions/FilterButton";
import TxTypeSelect from "../partials/actions/TxTypeSelect";
import DCLDateSelect from "../partials/actions/DCLDateSelect";
import DCLFilterButton from "../partials/actions/DCLFilterButton";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

function DCLHeatmapPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(5);
  const [selectedDepth, setSelectedDepth] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTxType, setSelectedTxType] = useState(2);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [selectedPercentiles, setSelectedPercentiles] = useState([]);

  const handleClick = (cell, event) => {
    setShowHeatmap(!showHeatmap);
  };
  const tabOptions = [
    {
      id: 0,
      tab: "Avg Price",
    },
    {
      id: 1,
      tab: "Transactions",
    },
  ];

  // x 314
  // y 309
  const depthOptions = [314].map((i, idx) => {
    return { id: idx, grid: `${i}x309` };
  });

  const colorHashmap = {
    1: "#F6F3C2",
    2: "#FFDB8F",
    3: "#FCAC64",
    4: "#F17043",
    5: "#A80426",
  };

  const colorRanges = ["1", "2-5", "6-10", "11-50", "51+"];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* tabs */}
            {/* <div className="relative mb-8">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-8">
                {tabOptions.map((tab) => {
                  return (
                    <button
                      key={tab.id}
                      className="mr-6 first:pl-8 last:mr-0"
                      onClick={() => {
                        setSelectedTab(tab.id);
                      }}
                    >
                      <div
                        className={`block pb-2 font-medium whitespace-nowrap ${
                          tab.id === selectedTab
                            ? "text-indigo-500 border-indigo-500 border-b-2"
                            : "text-slate-600"
                        }`}
                      >
                        {tab.tab}
                      </div>
                    </button>
                  );
                })}
              </ul>
            </div> */}

            {/* <div className="flex justify-end items-end mb-4"> */}
            <div className="flex flex-col items-end md:flex-row md:justify-end md:items-end mb-8">
              {/* Depth */}
              {/* <div className="mr-2 mb-2 md:mb-0">
                <span className="font-medium mr-2">HeatMap Depth:</span>
                <div class="inline-flex text-sm font-medium">
                  <div>
                    <button
                      onClick={() => {
                        setSelectedDepth(selectedDepth - 1);
                      }}
                      class="inline-flex items-center justify-center rounded-l leading-5 px-3.5 py-2 bg-white border border-slate-200 text-indigo-500 shadow-sm disabled:text-slate-300 disabled:cursor-not-allowed"
                      disabled={selectedDepth === 0}
                    >
                      -
                    </button>
                  </div>
                  <div>
                    <div class="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white border border-slate-200 text-slate-600 shadow-sm">
                      {depthOptions.find((o) => o.id === selectedDepth).grid}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedDepth(selectedDepth + 1);
                      }}
                      class="inline-flex items-center justify-center rounded-r leading-5 px-3.5 py-2 bg-white border border-slate-200 text-indigo-500 shadow-sm disabled:text-slate-300 disabled:cursor-not-allowed"
                      disabled={selectedDepth === depthOptions.length - 1}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div> */}
              <DCLFilterButton
                selectedPercentiles={selectedPercentiles}
                setSelectedPercentiles={setSelectedPercentiles}
              />
              {/* Transaction Type */}
              {selectedTab === 1 && (
                <TxTypeSelect
                  selected={selectedTxType}
                  setSelected={setSelectedTxType}
                />
              )}

              {/* Date */}
              <DCLDateSelect
                selected={selectedDate}
                setSelected={setSelectedDate}
              />
            </div>

            <div className="flex flex-col items-start mb-1 ml-1 mt-8">
              <div className="font-bold mb-2">
                Bands (Low to High) of Concurrent Users
              </div>
              <div className="flex flex-wrap">
                {Object.values(colorHashmap).map((color, idx) => {
                  return (
                    <div className="flex items-center mb-2" key={idx}>
                      <div
                        className="w-4 h-4 rounded"
                        style={{ backgroundColor: color }}
                      />
                      <div className="ml-1 mr-3">
                        {colorRanges[idx]}
                        {/* {`${idx < 9 ? (idx + 1) * 10 : (idx + 1 + 9) * 5}%`} */}{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Cards */}
            <div
              onClick={handleClick}
              // aspect-square
              className={`aspect-[314/309] shadow-lg rounded-sm border border-gray-200 bg-cover bg-white ${
                showHeatmap ? "bg-dcl-map-dark" : "bg-dcl-map"
              }`}
            >
              {showHeatmap && (
                <DCLHeatmap
                  date={selectedDate}
                  selectedTxType={selectedTxType}
                  depth={selectedDepth}
                  selectedTab={selectedTab}
                  showHeatmap={showHeatmap}
                  setShowHeatmap={setShowHeatmap}
                  selectedPercentiles={selectedPercentiles}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default DCLHeatmapPage;
