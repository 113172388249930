import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import BarChart from "../charts/BarChart";
import MultiLineChartDCL from "../charts/MultiLineChartDCL";
import LineChartNew from "../charts/LineChartNew";
import DCLStatsTableWrapper from "../partials/wrappers/DCLStatsTableWrapper";
import StatsTableWrapper from "../partials/wrappers/StatsTableWrapper";

function PlatformPagesNew({ world, page }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const argMap = {
    decentraland: {
      metadata: [
        {
          title: "PRICE: median avg price by Distance to Road",
          subtitle: "Decentraland LAND only; last 12 months",
          component: BarChart,
          arg: {
            world: "decentraland",
            chartType: "medianAvgP12mPerTrait",
            name: "Distance to Road",
          },
        },
        {
          title: "PRICE: median avg price per month by Distance to Road",
          subtitle: "Decentraland LAND only; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "decentraland",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "Distance to Road",
          },
        },
        {
          title: "PRICE: mean avg price by Distance to Road",
          subtitle: "Decentraland LAND only; last 12 months",
          component: BarChart,
          arg: {
            world: "decentraland",
            chartType: "meanAvgP12mPerTrait",
            name: "Distance to Road",
          },
        },
        {
          title: "PRICE: mean avg price per month by Distance to Road",
          subtitle: "Decentraland LAND only; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "decentraland",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "Distance to Road",
          },
        },
        {
          title: "PRICE: median avg price by Distance to Plaza",
          subtitle: "Decentraland LAND only; last 12 months",
          component: BarChart,
          arg: {
            world: "decentraland",
            chartType: "medianAvgP12mPerTrait",
            name: "Distance to Plaza",
          },
        },
        {
          title: "PRICE: median avg price per month by Distance to Plaza",
          subtitle: "Decentraland LAND only; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "decentraland",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "Distance to Plaza",
          },
        },
        {
          title: "PRICE: mean avg price by Distance to Plaza",
          subtitle: "Decentraland LAND only; last 12 months",
          component: BarChart,
          arg: {
            world: "decentraland",
            chartType: "meanAvgP12mPerTrait",
            name: "Distance to Plaza",
          },
        },
        {
          title: "PRICE: mean avg price per month by Distance to Plaza",
          subtitle: "Decentraland LAND only; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "decentraland",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "Distance to Plaza",
          },
        },
      ],
      traffic: [
        /* {
          title: "TRAFFIC: Avg Concurrent Users by hour",
          subtitle: "Decentraland",
          component: LineChartNew,
          arg: {
            world: "decentraland",
            chartType: "hourly",
            name: "totalOnlineUsers",
          },
        }, */
        {
          title: "TRAFFIC: Avg Concurrent Users by day",
          subtitle: "Decentraland",
          component: LineChartNew,
          arg: {
            world: "decentraland",
            chartType: "daily",
            name: "totalOnlineUsers",
          },
        },
        {
          title: "TRAFFIC: Avg concurrent users per parcel",
          subtitle: "Decentraland",
          component: DCLStatsTableWrapper,
          arg: {
            world: "decentraland",
            chartType: "userParcels",
            name: "userParcels",
          },
        },
      ],
    },
    byoLand: {
      metadata: [
        {
          title: "PRICE: median avg price by Abundancy",
          subtitle: "BYO Land; last 12 months",
          component: BarChart,
          arg: {
            world: "byoLand",
            chartType: "medianAvgP12mPerTrait",
            name: "Abundancy",
          },
        },
        {
          title: "PRICE: median avg price per month by Abundancy",
          subtitle: "BYO Land; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "byoLand",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "Abundancy",
          },
        },
        {
          title: "PRICE: mean avg price by Abundancy",
          subtitle: "BYO Land; last 12 months",
          component: BarChart,
          arg: {
            world: "byoLand",
            chartType: "meanAvgP12mPerTrait",
            name: "Abundancy",
          },
        },
        {
          title: "PRICE: mean avg price per month by Abundancy",
          subtitle: "BYO Land; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "byoLand",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "Abundancy",
          },
        },
        {
          title: "PRICE: median avg price by Zone",
          subtitle: "BYO Land; last 12 months",
          component: BarChart,
          arg: {
            world: "byoLand",
            chartType: "medianAvgP12mPerTrait",
            name: "Zone",
          },
        },
        {
          title: "PRICE: median avg price per month by Zone",
          subtitle: "BYO Land; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "byoLand",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "Zone",
          },
        },
        {
          title: "PRICE: mean avg price by Zone",
          subtitle: "BYO Land; last 12 months",
          component: BarChart,
          arg: {
            world: "byoLand",
            chartType: "meanAvgP12mPerTrait",
            name: "Zone",
          },
        },
        {
          title: "PRICE: mean avg price per month by Zone",
          subtitle: "BYO Land; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "byoLand",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "Zone",
          },
        },
      ],
    },
    somniumSpace: {
      metadata: [
        {
          title: "PRICE: median avg price by Parcel size",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgP12mPerTrait",
            name: "Parcel size",
          },
        },
        {
          title: "PRICE: median avg price per month by Parcel size",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "Parcel size",
          },
        },
        {
          title: "PRICE: mean avg price by Parcel size",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgP12mPerTrait",
            name: "Parcel size",
          },
        },
        {
          title: "PRICE: mean avg price per month by Parcel size",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "Parcel size",
          },
        }, ////
        {
          title: "PRICE: median avg price by Waterfront",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgP12mPerTrait",
            name: "waterfront_status",
          },
        },
        {
          title: "PRICE: median avg price per month by Waterfront",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "waterfront_status",
          },
        },
        {
          title: "PRICE: mean avg price by Waterfront",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgP12mPerTrait",
            name: "waterfront_status",
          },
        },
        {
          title: "PRICE: mean avg price per month by Waterfront",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "waterfront_status",
          },
        }, ////
        /* {
          title: "PRICE: median avg price by Roadside",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgP12mPerTrait",
            name: "roadside_status",
          },
        },
        {
          title: "PRICE: median avg price per month by Roadside",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "roadside_status",
          },
        },
        {
          title: "PRICE: mean avg price by Roadside",
          subtitle: "Somnium Space; last 12 months",
          component: BarChart,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgP12mPerTrait",
            name: "roadside_status",
          },
        },
        {
          title: "PRICE: mean avg price per month by Roadside",
          subtitle: "Somnium Space; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "somniumSpace",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "roadside_status",
          },
        }, */ ////
      ],
      traffic: [
        /* {
          title: "TRAFFIC: Avg Concurrent Users by hour",
          subtitle: "Decentraland",
          component: LineChartNew,
          arg: {
            world: "decentraland",
            chartType: "hourly",
            name: "totalOnlineUsers",
          },
        }, */
        {
          title: "TRAFFIC: Avg Concurrent Users by day",
          subtitle: "Somnium Space",
          component: LineChartNew,
          arg: {
            world: "somniumSpace",
            chartType: "daily",
            name: "totalOnlineUsers",
          },
        },
        {
          title: "TRAFFIC: Avg concurrent users per parcel",
          subtitle: "Somnium Space",
          component: StatsTableWrapper,
          parameters: {
            href: "https://somniumspace.com/parcel/",
            keyName: "Parcel ID",
            parcelNames: true,
          },
          arg: {
            world: "somniumSpace",
            chartType: "userParcels",
            name: "userParcels",
          },
        },
      ],
    },
    worldwideWebb: {
      metadata: [
        {
          title: "PRICE: median avg price by Type",
          subtitle: "Worldwide Webb; last 12 months",
          component: BarChart,
          arg: {
            world: "worldwideWebb",
            chartType: "medianAvgP12mPerTrait",
            name: "type",
          },
        },
        {
          title: "PRICE: median avg price per month by Type",
          subtitle: "Worldwide Webb; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "worldwideWebb",
            chartType: "medianAvgPricePerTraitPerMonth",
            name: "type",
          },
        },
        {
          title: "PRICE: mean avg price by Type",
          subtitle: "Worldwide Webb; last 12 months",
          component: BarChart,
          arg: {
            world: "worldwideWebb",
            chartType: "meanAvgP12mPerTrait",
            name: "type",
          },
        },
        {
          title: "PRICE: mean avg price per month by Type",
          subtitle: "Worldwide Webb; last 12 months",
          component: MultiLineChartDCL,
          arg: {
            world: "worldwideWebb",
            chartType: "meanAvgPricePerTraitPerMonth",
            name: "type",
          },
        },
      ],
    },
    nftWorlds: {
      traffic: [
        /* {
          title: "TRAFFIC: Avg Concurrent Users by hour",
          subtitle: "Decentraland",
          component: LineChartNew,
          arg: {
            world: "decentraland",
            chartType: "hourly",
            name: "totalOnlineUsers",
          },
        }, */
        {
          title: "TRAFFIC: Avg Concurrent Users by day",
          subtitle: "NFT Worlds",
          component: LineChartNew,
          arg: {
            world: "nftWorlds",
            chartType: "daily",
            name: "totalOnlineUsers",
          },
        },
        {
          title: "TRAFFIC: Avg concurrent users per parcel",
          subtitle: "NFT Worlds",
          component: StatsTableWrapper,
          parameters: {
            href: "https://www.nftworlds.com/",
            keyName: "World ID",
            parcelNames: true,
          },
          arg: {
            world: "nftWorlds",
            chartType: "userParcels",
            name: "userParcels",
          },
        },
      ],
    },
    cryptovoxels: {
      traffic: [
        /* {
          title: "TRAFFIC: Avg Concurrent Users by hour",
          subtitle: "Decentraland",
          component: LineChartNew,
          arg: {
            world: "decentraland",
            chartType: "hourly",
            name: "totalOnlineUsers",
          },
        }, */
        {
          title: "TRAFFIC: Avg Concurrent Users by day",
          subtitle: "Cryptovoxels",
          component: LineChartNew,
          arg: {
            world: "voxels",
            chartType: "daily",
            name: "totalOnlineUsers",
          },
        },
        {
          title: "TRAFFIC: Avg concurrent users per parcel",
          subtitle: "Cryptovoxels",
          component: StatsTableWrapper,
          parameters: {
            href: "https://www.voxels.com/parcels/",
            keyName: "Parcel ID",
            parcelNames: true,
          },
          arg: {
            world: "voxels",
            chartType: "userParcels",
            name: "userParcels",
          },
        },
      ],
    },
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {argMap[world][page].map((i, idx) => {
                const { title, subtitle, arg } = i;
                const parameters = i.parameters ? i.parameters : {};

                const ChartComponent = i["component"];

                return title === "TRAFFIC: Avg concurrent users per parcel" ? (
                  <ChartComponent
                    arg={arg}
                    parameters={parameters}
                    key={title + world}
                  />
                ) : (
                  <div
                    key={title + world}
                    className="flex flex-col h-[393px] col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200"
                  >
                    <header className="px-5 py-4 ">
                      <h2
                        className="font-semibold text-sm text-gray-800"
                        style={{
                          fontFamily: "IBM Plex Sans",
                          fontWeight: "500",
                          fontSize: 14,
                          color: "black",
                          textAnchor: "middle",
                        }}
                      >
                        {
                          title /*`PRICE: ${avg_type} avg price per month by ${trait_type}`*/
                        }
                      </h2>
                      <div>
                        <p
                          className="italic text-black font-medium font text-xs"
                          style={{
                            fontFamily: "IBM Plex Sans",
                            fontSize: 14,
                            color: "black",
                            textAnchor: "middle",
                          }}
                        >
                          {subtitle}
                        </p>
                      </div>
                    </header>

                    {/* bunun disinda div olmali stretch results diye class var dune'da */}
                    <ParentSize>
                      {({ width, height }) => (
                        <ChartComponent
                          width={width}
                          height={height}
                          arg={arg}
                          parameters={parameters}
                        />
                      )}
                    </ParentSize>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PlatformPagesNew;
