import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { desktopCarouselData } from "../Constants/DesktopCarouselData";
export const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <>
      <div className="carousel-container">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          statusFormatter={false}
          stopOnHover={false}
          showArrows={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
        >
          {desktopCarouselData.map((x) => {
            return( 
            <div key={x.id}>
              <img src={x.imgSrc} alt="img1" />
            </div>)
          })}
        </Carousel>
        <div
          onClick={next}
          className="slider-arrow right ghost-button-animation w-slider-arrow-right"
          role="button"
          tabIndex={0}
          aria-controls="w-slider-mask-0"
          aria-label="next slide"
        >
          <img
            src="https://assets-global.website-files.com/61e0c851bed1e44622183616/61e0d4e2e7958ad9147e4b0d_Big%20Arrow%20Right.svg"
            loading="lazy"
            alt=""
            className="big-arrow"
          />
        </div>
        <div
          onClick={prev}
          className="slider-arrow ghost-button-animation w-slider-arrow-left"
          role="button"
          tabIndex={0}
          aria-controls="w-slider-mask-0"
          aria-label="previous slide"
        >
          <img
            src="https://assets-global.website-files.com/61e0c851bed1e44622183616/61e0d4e2e7958a2e587e4b0e_Big%20Arrow.svg"
            loading="lazy"
            alt=""
            className="big-arrow"
          />
        </div>
      </div>
    </>
  );
};
