import React from "react";
import { useLocation } from "react-router-dom";
import SidebarGroupItems from "./SidebarGroupItems";
import SidebarLinkGroup from "./SidebarLinkGroup";

const SidebarGroup = ({ sidebarExpanded, setSidebarExpanded, title }) => {
  const urlName = title
    .replaceAll("-", "")
    .replaceAll(":", "")
    .replaceAll("  ", " ")
    .replaceAll(" ", "-")
    .toLowerCase();
  const location = useLocation();
  const { pathname } = location;

  const childNames =
    urlName === "summary"
      ? ["MM10 Index", "All Worlds", "All Owners", "Barons"]
      : urlName === "other-worlds"
      ? [
          "Aether",
          "Arcona",
          "Crypto Unicorns",
          "DeNations",
          "Ember Sword",
          "Etherland",
          "Gamium Lands",
          "Influence Asteroids",
          "League of Kingdoms",
          "Mavia Land",
          "Next Earth",
          "OVR Land",
          "Somnium Worlds",
          "Substrata",
          "SuperWorld",
          "The Farside",
          "Wilder World",
        ]
      : urlName === "sandbox"
      ? [
          "Price",
          "Price Heatmap",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Total Ecosystem",
        ]
      : ["byo-land", "worldwide-webb"].includes(urlName)
      ? [
          "Metadata",
          "Price",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Total Ecosystem",
        ]
      : urlName === "somnium-space"
      ? [
          "Metadata",
          "Price",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Total Ecosystem",
          "Traffic",
        ]
      : ["netvrk", "fluf-world", "treeverse"].includes(urlName)
      ? [
          "Price",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Total Ecosystem",
        ]
      : urlName === "axie-infinity"
      ? ["Metadata", "Price", "Value", "Units", "Ownership", "Transactions"]
      : urlName === "portals"
      ? ["Price", "Value", "Units", "Ownership", "Transactions"]
      : urlName === "nft-worlds"
      ? [
          "Price",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Projects",
          "Traffic",
        ]
      : urlName === "decentraland-land"
      ? [
          "Price",
          "Price Heatmap",
          "Metadata",
          "Value",
          "Units",
          "Ownership",
          "Transactions",
          "Total Ecosystem",
          "Traffic",
          "User Heatmap",
        ]
      : urlName === "cryptovoxels"
      ? ["Price", "Value", "Units", "Ownership", "Transactions", "Traffic"]
      : ["Price", "Value", "Units", "Ownership", "Transactions"];

  return (
    <SidebarLinkGroup activecondition={pathname.includes(urlName)}>
      {(handleClick, open) => {
        return (
          <React.Fragment>
            <a
              href="#0"
              className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                pathname.includes(urlName) && "hover:text-gray-200"
              }`}
              onClick={(e) => {
                e.preventDefault();
                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <path
                      className={`fill-current text-gray-600 ${
                        pathname.includes(urlName) && "text-indigo-500"
                      }`}
                      d="M0 20h24v2H0z"
                    />
                    <path
                      className={`fill-current text-gray-400 ${
                        pathname.includes(urlName) && "text-indigo-300"
                      }`}
                      d="M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z"
                    />
                  </svg>
                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    {title}
                  </span>
                </div>
                {/* Icon */}
                <div className="flex shrink-0 ml-2">
                  <svg
                    className={`w-3 h-3 shrink-0 ml-1 fill-current text-gray-400 ${
                      open && "transform rotate-180"
                    }`}
                    viewBox="0 0 12 12"
                  >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                  </svg>
                </div>
              </div>
            </a>
            <SidebarGroupItems
              parentUrlName={urlName}
              names={childNames}
              open={open}
            />
          </React.Fragment>
        );
      }}
    </SidebarLinkGroup>
  );
};

export default SidebarGroup;
