import React from "react";

import { useRealmApp } from "../../RealmApp";
import TableDcl from "../../charts/TableDcl";
import dclSceneData from "../../data/sceneData.json";

function DCLStatsTableWrapper({ arg }) {
  const app = useRealmApp();

  const [searchValue, setSearchValue] = React.useState("");
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const truncate = (val) =>
    val.length >= 15 ? val.slice(0, 6) + "..." + val.slice(-4) : val;

  const columns = React.useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US");
    const returnCellContent = (value) =>
      isNaN(value) ? "-" : String(formatter.format(value));
    return [
      {
        // digerlerini de formatla bole
        Header: "Coordinates",
        accessor: "coordinates",
        Cell: ({ value }) => (
          <a
            href={`https://play.decentraland.org/?position=${value.replace(
              " ",
              ""
            )}`}
            target="_blank"
            rel="noreferrer"
            title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {truncate(value)}
          </a>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => String(value),
      },
      {
        Header: "Today Avg",
        accessor: "todayAvg",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Today Peak",
        accessor: "todayPeak",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Yesterday Avg",
        accessor: "yesterdayAvg",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Yesterday Peak",
        accessor: "yesterdayPeak",
        Cell: ({ value }) => returnCellContent(value),
      },
      /* {
        Header: "Two Days Ago",
        accessor: "twoDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Three Days Ago",
        accessor: "threeDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Four Days Ago",
        accessor: "fourDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Five Days Ago",
        accessor: "fiveDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Six Days Ago",
        accessor: "sixDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "Seven Days Ago",
        accessor: "sevenDaysAgo",
        Cell: ({ value }) => returnCellContent(value),
      }, */

      {
        Header: "P7d Avg",
        accessor: "p7dAvg",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "P7d Peak",
        accessor: "p7dPeak",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "P30d Avg",
        accessor: "p30dAvg",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "P30d Peak",
        accessor: "p30dPeak",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "All Time Avg",
        accessor: "average",
        Cell: ({ value }) => returnCellContent(value),
      },
      {
        Header: "All Time Peak",
        accessor: "peak",
        Cell: ({ value }) => returnCellContent(value),
      },
    ];
  }, []);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      async function fetchAPI() {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          // log the time
          console.log("firs", new Date().toLocaleTimeString());
          let response = await app.currentUser.functions.getChartData(arg);
          let parcelsAggregated = {};

          // log the time
          console.log("fetchAPI", new Date().toLocaleTimeString());
          let sceneData = dclSceneData
            .filter((i) => i.base !== null)
            .map((i) => {
              i.base = i.base.replace(",", ", ");
              i.parcels = i.parcels.map((j) => j.replace(",", ", "));
              return i;
            });

          let baseParcelsMap = {};
          let namesMap = {};
          sceneData.forEach((i) => {
            i.parcels.forEach((j) => {
              baseParcelsMap[j] = i.base;
            });

            namesMap[i.base] = i.title != null ? i.title : "-";
          });

          response.forEach((i) => {
            Object.keys(i["userParcels"]).forEach((key) => {
              let refinedKey = baseParcelsMap[key] ? baseParcelsMap[key] : key;
              // if key not in parcelsAggregated add it
              if (!parcelsAggregated[refinedKey]) {
                parcelsAggregated[refinedKey] = [
                  {
                    timestamp: i["timestamp"],
                    count: i["userParcels"][key],
                  },
                ];
              } else {
                // find if timestamp already exists
                let foundObj = parcelsAggregated[refinedKey].find(
                  (j) => j.timestamp === i["timestamp"]
                );

                if (foundObj) {
                  // if timestamp exists, add count
                  foundObj.count += i["userParcels"][key];
                } else {
                  // if timestamp does not exist, add a new object for it
                  parcelsAggregated[refinedKey].push({
                    timestamp: i["timestamp"],
                    count: i["userParcels"][key],
                  });
                }
              }
            });
          });
          console.log(parcelsAggregated);
          console.log("aggregated");
          let data = [];
          Object.keys(parcelsAggregated).forEach((key) => {
            // get total count
            let total = parcelsAggregated[key].reduce(
              (acc, curr) => acc + curr.count,
              0
            );

            // get rounded average
            let average = Math.round(total / parcelsAggregated[key].length);
            // get peak
            let peak = Math.max(...parcelsAggregated[key].map((i) => i.count));

            const getPastNDaysAverage = (n) => {
              let now = new Date().getTime();
              let currentDay = now - (now % 86400000);

              let pastNDays = parcelsAggregated[key].filter(
                (i) =>
                  // less than current day and greater or equal to current day - n days
                  currentDay > new Date(i.timestamp).getTime() &&
                  new Date(i.timestamp).getTime() >=
                    currentDay - n * 24 * 60 * 60 * 1000 // n days in milliseconds
              );
              let pastNDaysTotal = pastNDays.reduce(
                (acc, curr) => acc + curr.count,
                0
              );
              const avg = Math.round(pastNDaysTotal / pastNDays.length);
              return isNaN(avg) ? 0 : avg;
            };

            const getPastNDaysPeak = (n) => {
              let now = new Date().getTime();
              let currentDay = now - (now % 86400000);

              let pastNDays = parcelsAggregated[key].filter(
                (i) =>
                  // less than current day and greater or equal to current day - n days
                  currentDay > new Date(i.timestamp).getTime() &&
                  new Date(i.timestamp).getTime() >=
                    currentDay - n * 24 * 60 * 60 * 1000 // n days in milliseconds
              );

              let pastNDaysPeakValue = pastNDays.reduce(
                (acc, curr) => Math.max(acc, curr.count),
                0
              );

              return isNaN(pastNDaysPeakValue) ? 0 : pastNDaysPeakValue;
            };

            const getNDaysAgoDailyAverage = (n) => {
              let now = new Date().getTime();
              let currentDay = now - (now % 86400000);

              let NDaysAgo = parcelsAggregated[key].filter(
                (i) =>
                  // less than current day - (n - 1) days and greater or equal to current day - n days
                  currentDay - (n - 1) * 24 * 60 * 60 * 1000 >
                    new Date(i.timestamp).getTime() &&
                  new Date(i.timestamp).getTime() >=
                    currentDay - n * 24 * 60 * 60 * 1000 // n days in milliseconds
              );
              let NDaysAgoTotal = NDaysAgo.reduce(
                (acc, curr) => acc + curr.count,
                0
              );

              const avg = Math.round(NDaysAgoTotal / NDaysAgo.length);
              return isNaN(avg) ? 0 : avg;
            };

            const getNDaysAgoDailyPeak = (n) => {
              let now = new Date().getTime();
              let currentDay = now - (now % 86400000);

              let NDaysAgo = parcelsAggregated[key].filter(
                (i) =>
                  // less than current day - (n - 1) days and greater or equal to current day - n days
                  currentDay - (n - 1) * 24 * 60 * 60 * 1000 >
                    new Date(i.timestamp).getTime() &&
                  new Date(i.timestamp).getTime() >=
                    currentDay - n * 24 * 60 * 60 * 1000 // n days in milliseconds
              );

              let NDaysAgoPeakValue = NDaysAgo.reduce(
                (acc, curr) => Math.max(acc, curr.count),
                0
              );

              return isNaN(NDaysAgoPeakValue) ? 0 : NDaysAgoPeakValue;
            };

            let dailyHashMap = {
              today: 0,
              yesterday: 1,
            };

            let dailyObj = {};

            // update dailyObj values with getNDaysAgoDailyAverage results
            Object.keys(dailyHashMap).forEach((key) => {
              dailyObj[key + "Avg"] = getNDaysAgoDailyAverage(
                dailyHashMap[key]
              );
              dailyObj[key + "Peak"] = getNDaysAgoDailyPeak(dailyHashMap[key]);
            });

            // get past 7 days rounded average
            let p7dAvg = getPastNDaysAverage(7);
            let p7dPeak = getPastNDaysPeak(7);

            // get past 30 days rounded average
            let p30dAvg = getPastNDaysAverage(30);
            let p30dPeak = getPastNDaysPeak(30);

            data.push({
              coordinates: key,
              name: namesMap[key] ? namesMap[key] : "-",
              ...dailyObj,
              average,
              peak,
              p7dAvg,
              p7dPeak,
              p30dAvg,
              p30dPeak,
            });
          });

          // sort data by average desc
          data.sort((a, b) => b.average - a.average);
          let coordinates = data.map((i) => i.coordinates);
          // get max and min x y coordinates and log them
          let maxX = Math.max(...coordinates.map((i) => i.split(",")[0]));
          let minX = Math.min(...coordinates.map((i) => i.split(",")[0]));
          let maxY = Math.max(...coordinates.map((i) => i.split(",")[1]));
          let minY = Math.min(...coordinates.map((i) => i.split(",")[1]));
          // log the time
          console.log("done", new Date().toLocaleTimeString());
          setRawData(data);
          setLoading(false);
        }
      }

      fetchAPI();
    },
    [app.currentUser.functions, arg]
  );

  React.useEffect(() => {
    if (searchValue !== "") {
      setData(
        rawData.filter((i) => {
          // if searchValue is in coordinates or name return true
          const lowercasedSearchValue = searchValue.toLowerCase();
          return (
            i.coordinates
              .replaceAll(" ", "")
              .includes(lowercasedSearchValue.replaceAll(" ", "")) ||
            i.name.toLowerCase().includes(lowercasedSearchValue.toLowerCase())
          );
        })
      );
      //setLoading(true);
    } else {
      setData(rawData);
    }
  }, [rawData, searchValue]);

  return (
    <TableDcl
      title="TRAFFIC: Avg concurrent users per parcel"
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      tablePageSize={20}
      hasSearchBar={true}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      manualPagination={false}
    />
  );
}

export default DCLStatsTableWrapper;
