import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
//import DuneEmbed from "../partials/DuneEmbed";
import DuneAllWorldsEmbed from "../partials/DuneAllWorldsEmbed";

function NFTWorldsProjects({ data, type }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabOptions = [
    {
      id: 0,
      tab: "Skuxxverse",
    },
    {
      id: 1,
      tab: "Hero Galaxy",
    },
    {
      id: 2,
      tab: "White Sands",
    },
    {
      id: 3,
      tab: "Bad Baby Dinos",
    },
  ];

  const tabMap = {
    0: "skuxxverse",
    1: "hero_galaxy",
    2: "white_sands",
    3: "bad_baby_dinos",
  };

  const widths = {
    skuxxverse: 12,
    hero_galaxy: 12,
    white_sands: 12,
    bad_baby_dinos: 12,
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* tabs */}
            <div className="relative mb-8">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-8">
                {tabOptions.map((tab) => {
                  return (
                    <button
                      key={tab.id}
                      className="mr-6 first:pl-8 last:mr-0"
                      onClick={() => {
                        setSelectedTab(tab.id);
                      }}
                    >
                      <div
                        className={`block pb-2 font-medium whitespace-nowrap ${
                          tab.id === selectedTab
                            ? "text-indigo-500 border-indigo-500 border-b-2"
                            : "text-slate-600"
                        }`}
                      >
                        {tab.tab}
                      </div>
                    </button>
                  );
                })}
              </ul>
            </div>
            <div className="grid grid-cols-12 gap-3 items-center">
              {/* Dune embeds */}
              {data[tabMap[selectedTab]].map((i, idx) => (
                <DuneAllWorldsEmbed
                  key={tabMap[selectedTab] + idx}
                  title={i.title}
                  isTable={i.is_table}
                  src={i.embed_url}
                  width={widths[tabMap[selectedTab]]}
                />
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default NFTWorldsProjects;
