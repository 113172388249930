import React, { useEffect } from "react";
import Table from "../../charts/Table";
import { useRealmApp } from "../../RealmApp";

function TableWrapper2() {
  const app = useRealmApp();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const truncate = (val) =>
    val.length >= 15 ? val.slice(0, 6) + "..." + val.slice(-4) : val;

  const format_tw = (val) => (val[0] === "@" ? val.slice(1) : val);

  const columns = React.useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US");
    const priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return [
      {
        Header: "Wallet",
        accessor: "_id",
        Cell: ({ row, value }) => (
          <a
            href={`https://etherscan.io/address/${value}`}
            target="_blank"
            rel="noreferrer"
            title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {row.original.ens_name != null
              ? row.original.ens_name
              : truncate(value)}
          </a>
        ),
      },
      {
        Header: "OpenSea Name",
        accessor: "opensea_name",
        Cell: ({ row, value }) => (
          <div className="flex flex-row justify-center align-center">
            <a
              href={`https://opensea.io/${row.original._id}`}
              target="_blank"
              rel="noreferrer"
              title={value}
              className={`text-sky-500 visited:text-fuchsia-700 ${
                row.original.opensea_verified ? "ml-4" : ""
              }`}
            >
              {value == null ? "Unnamed" : truncate(value)}
            </a>
            {row.original.opensea_verified ? (
              <svg
                className="mt-[3px] ml-1 w-4 h-4 fill-current text-sky-500"
                viewBox="0 0 16 16"
              >
                <title>Verified by OpenSea</title>
                <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"></path>
              </svg>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Sandbox Name",
        accessor: "sandbox_username",
        Cell: ({ row, value }) => (
          <>
            {value != null ? (
              <a
                href={`https://www.sandbox.game/users/${value.toLowerCase()}/${
                  row.original.sandbox_id
                }/`}
                target="_blank"
                rel="noreferrer"
                title={value}
                className="text-sky-500 visited:text-fuchsia-700"
              >
                {value === null ? "Unnamed" : truncate(value)}
              </a>
            ) : (
              <div>-</div>
            )}
          </>
        ),
      },
      {
        Header: "Twitter",
        accessor: "opensea_twitter_username",
        Cell: ({ row, value }) => {
          // null null value/null
          // notnull  ~ value
          // null notnull notnull
          const sand_twitter = row.original.sandbox_twitter_username;
          if (value === null && sand_twitter !== null) {
            value = sand_twitter;
          }
          return (
            <>
              {value != null ? (
                <a
                  href={`https://twitter.com/${format_tw(value)}`}
                  target="_blank"
                  rel="noreferrer"
                  title={value}
                  className="text-sky-500 visited:text-fuchsia-700"
                >
                  {truncate("@" + format_tw(value))}
                </a>
              ) : (
                <div>-</div>
              )}
            </>
          );
        },
      },
      {
        Header: "Units",
        accessor: "count",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "30d Change",
        accessor: "1mchange",
        Cell: ({ row, value }) =>
          String(
            value === null
              ? "+" + row.original.count
              : value <= 0
              ? value
              : "+" + value
          ),
      },
      {
        Header: "3m Change",
        accessor: "3mchange",
        Cell: ({ row, value }) =>
          String(
            value === null
              ? "+" + row.original.count
              : value <= 0
              ? value
              : "+" + value
          ),
      },
      {
        Header: "12m Change",
        accessor: "12mchange",
        Cell: ({ row, value }) =>
          String(
            value === null
              ? "+" + row.original.count
              : value <= 0
              ? value
              : "+" + value
          ),
      },
      {
        Header: "% of Current Units",
        accessor: "percent_of_current_supply",
        Cell: ({ value }) => String((100 * value).toFixed(2) + "%"),
      },
      {
        Header: "% of Total Units",
        accessor: "percent_of_total_supply",
        Cell: ({ value }) => String((100 * value).toFixed(2) + "%"),
      },
      {
        Header: "Portfolio Value",
        accessor: "portfolio_value",
        Cell: ({ value }) => String(priceFormatter.format(Math.round(value))),
      },
    ];
  }, []);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      async function fetchAPI() {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          let response = await app.currentUser.functions.get_owners2(
            pageIndex,
            pageSize
          );
          let data = response["result"][0];

          setPageCount(Math.ceil(data.metadata.total / pageSize));
          setTotalCount(data.metadata.total);

          data = data.data;

          setData(data);
          setLoading(false);
        }
      }

      fetchAPI();
    },
    [app.currentUser.functions]
  );

  useEffect(() => {}, []);

  return (
    <Table
      title="OWNERSHIP: top owners ranked by units & portfolio value"
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      tablePageSize={10}
      totalCount={totalCount}
    />
  );
}

export default TableWrapper2;
