import React, { useState, useEffect } from "react";
import { useRealmApp } from "../RealmApp";
import { ResponsiveHeatMapCanvas } from "@nivo/heatmap";
import HeatmapCustomTooltip from "../partials/HeatmapCustomTooltip";
import BounceLoader from "react-spinners/BounceLoader";
import DCLPriceHeatmapCustomTooltip from "../partials/DCLPriceHeatmapCustomTooltip";
/* import dclSceneData from "../data/sceneData.json";
import { sceneData } from "../data/"; */

/* sceneData = dclSceneData.filter(
  (i) => i.parcels !== null && i.title !== null
); */

/* const sceneTitles = {};

sceneData
  .filter((i) => i.parcels !== null && i.title !== null)
  .forEach((i) => {
    i.parcels.forEach((p) => {
      sceneTitles[p] = i.title;
    });
  }); */

const DCLHeatmap = ({
  date,
  depth,
  setShowHeatmap,
  showHeatmap,
  handleClick,
  selectedTab,
  selectedTxType,
  selectedPercentiles,
}) => {
  const app = useRealmApp();
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);

  const isAvgTab = selectedTab === 0;

  const customColor = (datum) => {
    const deciles = currentData.deciles;

    let color = "";
    deciles.some((decile, idx) => {
      if (decile >= datum.value) {
        color =
          selectedPercentiles.includes(idx) || selectedPercentiles.length === 0
            ? colorHashmap[idx + 1]
            : "#1E1C32";
        return true;
      }
      return false;
    });
    return color;
  };

  const colorHashmap = {
    1: "#363B81",
    2: "#4874B3",
    3: "#75A9D0",
    4: "#AAD3E6",
    5: "#DAEEE5",
    6: "#F6F3C2",
    7: "#FFDB8F",
    8: "#FCAC64",
    9: "#F17043",
    10: "#D6322A",
    11: "#A80426",
  };

  const dateHashmap = {
    0: "1m",
    1: "3m",
    2: "12m",
    3: undefined,
  };

  const depthHashmap = {};
  const arr = [
    /* 5 * 5,
    10 * 10,
    15 * 15,
    25 * 25,
    50 * 50,
    75 * 75,
    150 * 150, */
    97026,
  ];
  arr.forEach((i, idx) => (depthHashmap[idx] = i));

  const txTypeHashmap = {
    0: "mints",
    1: "trades",
    2: "all",
  };

  const dateRange = dateHashmap[date];
  const sectorCount = depthHashmap[depth];
  const txType = txTypeHashmap[selectedTxType];
  let dimLen = depth == 0 ? 314 : Math.sqrt(sectorCount);

  const dataId = isAvgTab
    ? `${sectorCount}_${dateRange}`
    : `tx_${sectorCount}_${txType}_${dateRange}`;

  useEffect(() => {
    async function fetchAvg() {
      setLoading(true);
      let response = {};
      if (isAvgTab) {
        response = await app.currentUser.functions.get_dcl_price_heatmap_data(
          sectorCount,
          dateRange
        );
      } else {
        response = await app.currentUser.functions.get_dcl_heatmap_tx_data(
          sectorCount,
          txType,
          dateRange
        );
      }
      response.forEach((e) => {
        if (e.data.length === dimLen) return;

        const arr = [...Array(dimLen)];
        arr.forEach((_, i) => {
          let obj = e.data.find((o) => o.x === i + 1);
          if (obj === undefined) {
            e.data.push({ x: i + 1, y: null });
          }
        });
      });
      response.sort((a, b) => b.id - a.id);
      response.forEach((i) => i.data.sort((a, b) => a.x - b.x));

      // deciles
      const allValues = [];
      const deciles = [];
      response.forEach((i) => {
        i["data"].forEach((d) => {
          if (d.y != null) allValues.push(d.y);
        });
      });
      allValues.sort((a, b) => a - b);
      // may add these in deciles
      // const minVal = allValues[0]
      // const maxVal = allValues[allValues.length - 1]
      const elevenRange = [...Array(11).keys()];
      elevenRange.forEach((i) => {
        var t =
          i < 9
            ? Math.floor((allValues.length / 10) * (i + 1)) - 1
            : Math.floor((allValues.length / 20) * (i + 1 + 9)) - 1;
        deciles.push(allValues[t]);
      });
      setData([...data, { id: dataId, data: response, deciles: deciles }]);
      setCurrentData({ data: response, deciles: deciles });

      setLoading(false);
    }

    const obj = data.find((o) => o.id === dataId);
    if (!obj) {
      fetchAvg();
    } else {
      setCurrentData({ data: obj.data, deciles: obj.deciles });
    }
  }, [
    app.currentUser.functions,
    data,
    dataId,
    date,
    dateRange,
    dimLen,
    isAvgTab,
    sectorCount,
    selectedTab,
    txType,
  ]);

  return !loading ? (
    <ResponsiveHeatMapCanvas
      data={currentData.data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      valueFormat={isAvgTab ? "$," : ""}
      //valueFormat=">-.2s"
      colors={customColor} //;
      emptyColor="#1E1C32"
      opacity={0.7}
      activeOpacity={0.8}
      inactiveOpacity={0.7}
      enableLabels={false}
      hoverTarget="cell"
      tooltip={(i) => DCLPriceHeatmapCustomTooltip(i, dimLen, isAvgTab)}
      animate={false}
      onClick={handleClick}
    />
  ) : (
    <div className="flex items-center justify-center h-screen">
      <div className="translate-x-[-75px]">
        <BounceLoader color={"#3892BB"} size={150} />
      </div>
    </div>
  );
};

export default DCLHeatmap;
