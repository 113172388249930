import React from "react";

const DuneEmbed = ({ src, isTable, title }) => {
  return (
    <>
      {false ? ( //isTable ?
        <>
          <div className={`flex flex-col col-span-full sm:col-span-1`} />
          <div
            className={`flex flex-col col-span-full sm:col-span-10 bg-white shadow-lg rounded-sm border border-gray-200`}
          >
            <iframe
              title={title}
              src={src}
              height={379}
              loading="lazy"
              sandbox="allow-scripts allow-same-origin"
            ></iframe>
          </div>
          <div className={`flex flex-col col-span-full sm:col-span-1`} />
        </>
      ) : (
        <div
          className={`flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200`}
        >
          <iframe
            title={title}
            src={src}
            height={379}
            loading="lazy"
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default DuneEmbed;
