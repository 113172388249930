import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import ScatterPlot from "../charts/ScatterPlot";
import BarChartForAxie from "../charts/BarChartForAxie";
import BarChart from "../charts/BarChart";
import AxieTransactionsTableWrapper from "../partials/wrappers/AxieTransactionsTableWrapper";
import AxieTopOwnersTableWrapper from "../partials/wrappers/AxieTopOwnersTableWrapper";
import AxieTableWrapper from "../partials/wrappers/AxieTableWrapper";
import LineChart from "../charts/LineChart";
import MultiLineChart from "../charts/MultiLineChart";

function AxiePages({ page }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const argMap = {
    metadata: [
      /*
      mean included bar
      mean included line
      median included bar
      median included line

      mean excluded bar
      mean excluded line
      median excluded bar
      median excluded line      
      */
      {
        title:
          "PRICE: mean avg price by land type (Genesis lands included) (current rate)",
        subtitle: "Axie Infinity; last 12 months",
        component: BarChartForAxie,
        arg: "3",
        type: "bar",
      },
      {
        title:
          "PRICE: mean avg price per month per land type (Genesis lands included) (current rate)",
        subtitle: "Axie Infinity; all time",
        component: MultiLineChart,
        arg: 1,
        type: "multiline", // ustteki de multiline, key icin yaptim. bu duzenlenecek
      },
      {
        title:
          "PRICE: median avg price by land type (Genesis lands included) (current rate)",
        subtitle: "Axie Infinity; last 12 months",
        component: BarChartForAxie,
        arg: "4",
        type: "bar",
      },
      {
        title:
          "PRICE: median avg price per month per land type (Genesis lands included) (current rate)",
        subtitle: "Axie Infinity; all time",
        component: MultiLineChart,
        arg: 2,
        type: "multiline", // ustteki de multiline, key icin yaptim. bu duzenlenecek
      },
      {
        title:
          "PRICE: mean avg price by land type (Genesis lands excluded) (current rate)",
        subtitle: "Axie Infinity; last 12 months",
        component: BarChartForAxie,
        arg: "3",
        type: "bar",
      },
      {
        title:
          "PRICE: mean avg price per month per land type (Genesis lands excluded) (current rate)",
        subtitle: "Axie Infinity; all time",
        component: MultiLineChart,
        arg: 1,
        type: "line",
      },
      {
        title:
          "PRICE: median avg price by land type (Genesis lands excluded) (current rate)",
        subtitle: "Axie Infinity; last 12 months",
        component: BarChartForAxie,
        arg: "4",
        type: "bar",
      },
      {
        title:
          "PRICE: median avg price per month per land type (Genesis lands excluded) (current rate)",
        subtitle: "Axie Infinity; all time",
        component: MultiLineChart,
        arg: 2,
        type: "line",
      },
    ],
    price: [
      {
        title: "PRICE: avg price per month (current rate)",
        subtitle: "Axie Infinity; all time",
        component: BarChart,
        arg: 1,
        type: "bar",
      },
      {
        title: "PRICE: Avg price per week (current rate)",
        subtitle: "Axie Infinity; last 52 weeks",
        component: BarChart,
        arg: 2,
        type: "bar",
      },
      {
        title: "PRICE: avg price per day (current rate)",
        subtitle: "Axie Infinity; last 30 days",
        component: BarChart,
        arg: 3,
        type: "bar",
      },
      {
        title: "PRICE: Price per Unit per Transaction (P30d, current rate)",
        subtitle: "Axie Infinity; last 30 days",
        component: ScatterPlot,
        arg: 16,
        type: "scatter",
      },
    ],
    value: [
      {
        title: "VALUE: sales value per month (current rate)",
        subtitle: "Axie Infinity; all time",
        component: BarChart,
        arg: 4,
        type: "bar",
      },
      {
        title: "VALUE: cumulative value of LAND sold per month (current rate)",
        subtitle: "Axie Infinity; All time",
        component: BarChart,
        arg: 5,
        type: "bar",
      },
      {
        title: "VALUE: value sales per week (current rate)",
        subtitle: "Axie Infinity; last 52 weeks",
        component: BarChart,
        arg: 6,
        type: "bar",
      },
      {
        title: "VALUE: Avg value per day (current rate)",
        subtitle: "Axie Infinity; last 30 days",
        component: BarChart,
        arg: 7,
        type: "bar",
      },
    ],
    units: [
      {
        title: "UNITS: number of units of LAND sold per month",
        subtitle: "Axie Infinity; all time",
        component: BarChart,
        arg: 8,
        type: "bar",
      },
      {
        title: "UNITS: cumulative number of units of LAND sold per month",
        subtitle: "Axie Infinity; all time",
        component: BarChart,
        arg: 9,
        type: "bar",
      },
      {
        title: "UNITS: number of units of LAND sold per week",
        subtitle: "Axie Infinity; last 52 weeks",
        component: BarChart,
        arg: 10,
        type: "bar",
      },
      {
        title: "UNITS: number of units of LAND sold per day",
        subtitle: "Axie Infinity; last 30 days",
        component: BarChart,
        arg: 11,
        type: "bar",
      },
    ],
    ownership: [
      {
        title: "OWNERSHIP: number of unique owners (daily)",
        subtitle: "Axie Infinity; all time",
        component: LineChart,
        arg: 22,
        type: "line",
      },
      {
        title: "OWNERSHIP: number of new owners (monthly)",
        subtitle: "Axie Infinity; all time",
        component: BarChart,
        arg: 12,
        type: "bar",
      },
      {
        title: "OWNERSHIP: number of new owners (weekly)",
        subtitle: "Axie Infinity; last 52 weeks",
        component: BarChart,
        arg: 13,
        type: "bar",
      },
      {
        title: "OWNERSHIP: number of new owners (daily)",
        subtitle: "Axie Infinity; last 30 days",
        component: BarChart,
        arg: 14,
        type: "bar",
      },
      {
        title: "OWNERSHIP: number of owners by units held",
        subtitle: "Axie Infinity; current time",
        component: BarChart,
        arg: 15,
        type: "bar",
      },
      {
        title: "OWNERSHIP: top owners ranked by units & portfolio value",
        component: AxieTableWrapper,
        notes: "19 yazdim ama 19 degil, bunun querysi baska",
        arg: 19,
        type: "table",
      },
      {
        title: "OWNERS: Top Buyers (P30d)",
        component: AxieTopOwnersTableWrapper,
        arg: 20,
        type: "table",
      },
      {
        title: "OWNERS: Top Sellers (P30d)",
        component: AxieTopOwnersTableWrapper,
        arg: 21,
        type: "table",
      },
    ],
    transactions: [
      {
        title: "TRANSACTIONS: top transactions by value (P24h)",
        component: AxieTransactionsTableWrapper,
        arg: 17,
        type: "table",
      },
      {
        title: "TRANSACTIONS: top transactions by value (P30d)",
        component: AxieTransactionsTableWrapper,
        arg: 18,
        type: "table",
      },
      {
        title: "TRANSACTIONS: all transactions (all time)",
        component: AxieTransactionsTableWrapper,
        arg: 19,
        type: "table",
      },
    ],
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {argMap[page].map((i, idx) => {
                const { title, arg, type } = i;
                const ChartComponent = i["component"];

                if (type === "table") {
                  return (
                    <ChartComponent key={arg + type} arg={arg} title={title} />
                  );
                }
                const { subtitle } = i;

                return (
                  <div
                    key={arg}
                    className="flex flex-col h-[393px] col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200"
                  >
                    <header className="px-5 py-4 ">
                      <h2
                        className="font-semibold text-sm text-gray-800"
                        style={{
                          fontFamily: "IBM Plex Sans",
                          fontWeight: "500",
                          fontSize: 14,
                          color: "black",
                          textAnchor: "middle",
                        }}
                      >
                        {title}
                      </h2>
                      <div>
                        <p
                          className="italic text-black font-medium font text-xs"
                          style={{
                            fontFamily: "IBM Plex Sans",
                            fontSize: 14,
                            color: "black",
                            textAnchor: "middle",
                          }}
                        >
                          {subtitle}
                        </p>
                      </div>
                    </header>

                    {/* bunun disinda div olmali stretch results diye class var dune'da */}
                    <ParentSize>
                      {({ width, height }) => (
                        <ChartComponent
                          width={width}
                          height={height}
                          arg={arg}
                          excluded={
                            page === "metadata" && idx > 3 ? true : undefined
                          }
                        />
                      )}
                    </ParentSize>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AxiePages;
