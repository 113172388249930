import image1 from '../assets/images/desktop1.png'
import image2 from '../assets/images/desktop2.jpg'
import image3 from '../assets/images/desktop3.jpg'
import image4 from '../assets/images/desktop4.jpg'

export const desktopCarouselData = [
    {
        id:1,
        imgSrc : image1
    },
    {
        id:2,
        imgSrc : image2
    },
    {
        id:3,
        imgSrc : image3
    },
    {
        id:4,
        imgSrc : image4
    }
]