import image1 from '../assets/images/Heatmap.png'
import image2 from '../assets/images/Heatmap2.png'

export const rotateData = [
    {
        id:1,
        imgSrc : image1
    },
    {
        id:2,
        imgSrc : image2
    },
]