import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { useRealmApp, RealmAppProvider } from "./RealmApp";

import "./css/style.scss";

// css for front page, need to change this in the future
import "./frontPage/App.css";
//import "./charts/ChartjsConfig";

// Import pages

import Login from "./pages/LoginPage";

import * as Dashboards from "./embedData";
import PlatformPages from "./pages/PlatformPages";
import OwnersNew from "./pages/OwnersNew";
import HeatmapPage from "./pages/HeatmapPage";
import AxiePages from "./pages/AxiePages";
import PortalsPages from "./pages/PortalsPages";
import PlatformPagesNew from "./pages/PlatformPagesNew";
import Barons from "./pages/Barons";
import AllWorlds from "./pages/AllWorlds";
import { Home } from "./frontPage/Home";
import DCLHeatmapPage from "./pages/DCLHeatmapPage";
import NFTWorldsProjects from "./pages/NFTWorldsProjects";
import EcosystemPage from "./pages/EcosystemPage";
import DCLPriceHeatmapPage from "./pages/DCLPriceHeatmapPage";

export const APP_ID = process.env.REACT_APP_REALM_APP_ID;
const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <Redirect to="/login" />;
};

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  const isHome = location.pathname === "/";

  useEffect(() => {
    if (isHome) {
      document.querySelector("body").classList.add("home");
    } else {
      document.querySelector("body").classList.remove("home");
    }
  }, [isHome]); // triggered on route change

  //let titles = ["Free - Summary", "Free - Community", "Summary", "Sandbox", "Decentraland", "Cryptovoxels", "Somnium Space"]
  //titles = titles.map(title => title.replaceAll(' ', '').toLowerCase())
  //const urlName = title.replaceAll(' ', '').toLowerCase()
  //console.log(title)
  /* const dataMap = {
    "free-summary": Dashboards.community_total_market,
 
    "summary": Dashboards.total_market,
    "sandbox": Dashboards.sandbox,
    "decentraland": Dashboards.decentraland,
    "cryptovoxels": Dashboards.cryptovoxels,
    "somniumspace": Dashboards.somnium_space,
  } */

  return (
    <>
      <RealmAppProvider appId={APP_ID}>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/login">
            <Login />
          </Route>

          <Route exact path="/summary/mm10-index">
            <RequireLoggedInUser>
              <PlatformPages data={Dashboards.mm10} />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/summary/all-worlds">
            <RequireLoggedInUser>
              <AllWorlds data={Dashboards.all_worlds} />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/nft-worlds/projects">
            <RequireLoggedInUser>
              <NFTWorldsProjects data={Dashboards.nft_worlds_projects} />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/summary/all-owners">
            <RequireLoggedInUser>
              <OwnersNew />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/summary/barons">
            <RequireLoggedInUser>
              <Barons data={Object.values(Dashboards.barons).flat()} />
            </RequireLoggedInUser>
          </Route>

          {/* other worlds */}
          {[
            "aether",
            "arcona",
            "crypto-unicorns",
            "denations",
            "ember-sword",
            "etherland",
            "gamium-lands",
            "influence-asteroids",
            "league-of-kingdoms",
            "mavia-land",
            "next-earth",
            "ovr-land",
            "somnium-worlds",
            "substrata",
            "superworld",
            "the-farside",
            "wilder-world",
          ].map((i, idx) => (
            <Route exact path={`/other-worlds/${i}`} key={idx}>
              <RequireLoggedInUser>
                <PlatformPages
                  data={Dashboards.total_market[i.replaceAll("-", "_")]}
                />
              </RequireLoggedInUser>
            </Route>
          ))}

          {[
            //"SUMMARY",
            //"Axie Infinity",

            "Arcade Land",
            "BYO Land",
            "Cryptovoxels",
            "Decentraland - Estate",
            "Decentraland - Land",
            "Ethereum Towers",
            "Farmland",
            "Fluf World",
            "Matrix World",
            "MegaCryptopolis",
            "Metroverse - Blackout",
            "Metroverse - Genesis",
            "NFT Worlds",
            "Netvrk",
            "Otherside",
            "Polka City",
            "Sandbox",
            "Somnium Space",
            "Treeverse",
            "Troverse Planets",
            "Webaverse",
            "Worldwide Webb",
            //"Other Worlds"
          ].map((worldName, idx) => {
            const urlName = worldName
              .replaceAll("-", "")
              .replaceAll(":", "")
              .replaceAll("  ", " ")
              .replaceAll(" ", "-")
              .toLowerCase();
            const objName = urlName.replaceAll("-", "_");

            return ["price", "value", "units", "ownership", "transactions"].map(
              (i, pageIdx) => {
                return (
                  <Route exact path={`/${urlName}/${i}`} key={idx + pageIdx}>
                    <RequireLoggedInUser>
                      <PlatformPages data={Dashboards[objName][i]} />
                    </RequireLoggedInUser>
                  </Route>
                );
              }
            );
          })}

          {[
            "BYO Land",
            "Decentraland - Land",
            "Fluf World",
            "Netvrk",
            "Sandbox",
            "Somnium Space",
            "Treeverse",
            "Worldwide Webb",
          ].map((worldName, idx) => {
            const urlName = worldName
              .replaceAll("-", "")
              .replaceAll(":", "")
              .replaceAll("  ", " ")
              .replaceAll(" ", "-")
              .toLowerCase();
            const objName = urlName.replaceAll("-", "_");

            return (
              <Route exact path={`/${urlName}/total-ecosystem`} key={idx}>
                <RequireLoggedInUser>
                  <EcosystemPage
                    data={Dashboards["ecosystem"][objName]}
                    objName={objName}
                  />
                </RequireLoggedInUser>
              </Route>
            );
          })}

          {/* DCL heatmap */}
          <Route exact path={`/decentraland-land/user-heatmap`}>
            <RequireLoggedInUser>
              <DCLHeatmapPage />
            </RequireLoggedInUser>
          </Route>

          {/* DCL heatmap */}
          <Route exact path={`/decentraland-land/price-heatmap`}>
            <RequireLoggedInUser>
              <DCLPriceHeatmapPage />
            </RequireLoggedInUser>
          </Route>

          {/* sandbox heatmap */}
          <Route exact path={`/sandbox/price-heatmap`}>
            <RequireLoggedInUser>
              <HeatmapPage />
            </RequireLoggedInUser>
          </Route>

          {/* axie */}
          {[
            "metadata",
            "price",
            "value",
            "units",
            "ownership",
            "transactions",
          ].map((i, idx) => (
            <Route exact path={`/axie-infinity/${i}`} key={idx}>
              <RequireLoggedInUser>
                <AxiePages page={i} />
              </RequireLoggedInUser>
            </Route>
          ))}

          {/* portals */}
          {["price", "value", "units", "ownership", "transactions"].map(
            (i, idx) => (
              <Route exact path={`/portals/${i}`} key={idx}>
                <RequireLoggedInUser>
                  <PortalsPages page={i} />
                </RequireLoggedInUser>
              </Route>
            )
          )}

          {/* stats pages */}
          {/*  */}
          {["decentraland", "nft-worlds", "cryptovoxels", "somnium-space"].map(
            (i, idx) => {
              const route_name = i === "decentraland" ? "decentraland-land" : i;
              const world_name =
                i === "nft-worlds"
                  ? "nftWorlds"
                  : i === "somnium-space"
                  ? "somniumSpace"
                  : i;
              return (
                <Route exact path={`/${route_name}/traffic`}>
                  <RequireLoggedInUser>
                    <PlatformPagesNew world={world_name} page="traffic" />
                  </RequireLoggedInUser>
                </Route>
              );
            }
          )}

          {/* metadata pages */}
          <Route exact path={`/decentraland-land/metadata`}>
            <RequireLoggedInUser>
              <PlatformPagesNew world="decentraland" page="metadata" />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/worldwide-webb/metadata">
            <RequireLoggedInUser>
              <PlatformPagesNew world="worldwideWebb" page="metadata" />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/somnium-space/metadata">
            <RequireLoggedInUser>
              <PlatformPagesNew world="somniumSpace" page="metadata" />
            </RequireLoggedInUser>
          </Route>

          <Route exact path="/byo-land/metadata">
            <RequireLoggedInUser>
              <PlatformPagesNew world="byoLand" page="metadata" />
            </RequireLoggedInUser>
          </Route>
        </Switch>
      </RealmAppProvider>
    </>
  );
}

export default App;
