import React from "react";

const DuneBaronsEmbed = ({ src, isTable, title, width }) => {
  return (
    <>
      {width === 6 ? (
        <div
          className={`flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200`}
        >
          <iframe
            title={title}
            src={src}
            height={379}
            loading="lazy"
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      ) : width === 4 ? (
        <div
          className={`flex flex-col col-span-full sm:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200`}
        >
          <iframe
            title={title}
            src={src}
            height={379}
            loading="lazy"
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      ) : (
        <div
          className={`flex flex-col col-span-full sm:col-span-2 bg-white shadow-lg rounded-sm border border-gray-200`}
        >
          <iframe
            title={title}
            src={src}
            height={379}
            loading="lazy"
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default DuneBaronsEmbed;
