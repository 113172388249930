import React, { useState, useEffect } from "react";
import { useRealmApp } from "../RealmApp";
import { ResponsiveHeatMapCanvas } from "@nivo/heatmap";
import DCLHeatmapCustomTooltip from "../partials/DCLHeatmapCustomTooltip";
import BounceLoader from "react-spinners/BounceLoader";
import dclSceneData from "../data/sceneData.json";
import { sceneData } from "../data/";

/* sceneData = dclSceneData.filter(
  (i) => i.parcels !== null && i.title !== null
); */

const sceneTitles = {};

sceneData
  .filter((i) => i.parcels !== null && i.title !== null)
  .forEach((i) => {
    i.parcels.forEach((p) => {
      sceneTitles[p] = i.title;
    });
  });

const DCLHeatmap = ({
  date,
  depth,
  setShowHeatmap,
  showHeatmap,
  handleClick,
  selectedTab,
  selectedTxType,
  selectedPercentiles,
}) => {
  const app = useRealmApp();
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [loading, setLoading] = useState(false);

  const isAvgTab = selectedTab === 0;

  const customColor = (datum) => {
    const deciles = currentData.deciles;

    let color = "";
    deciles.some((decile, idx) => {
      if (decile >= datum.value) {
        color =
          selectedPercentiles.includes(idx) || selectedPercentiles.length === 0
            ? colorHashmap[idx + 1]
            : "#1E1C32";
        return true;
      }
      return false;
    });
    return color;
  };

  const colorHashmap = {
    1: "#F6F3C2",
    2: "#FFDB8F",
    3: "#FCAC64",
    4: "#F17043",
    5: "#A80426",
  };

  /* 
    {
      id: 0,
      period: "Live",
    },
    {
      id: 1,
      period: "Today",
    },
    {
      id: 2,
      period: "Yesterday",
    },
    {
      id: 3,
      period: "P7d",
    },
    {
      id: 4,
      period: "P30d",
    },
    {
      id: 3,
      period: "All Time",
    },

*/

  const dateHashmap = {
    0: "Live",
    1: "Today",
    2: "Yesterday",
    3: "P7d",
    4: "P30d",
    5: undefined,
  };

  // get current hours' timestamp in miliseconds without minutes
  const getCurrentHour = () => {
    const date = new Date();
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  };

  // getCurrentDay returns the current day in miliseconds
  const getCurrentDay = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  };

  // infinity timestamp
  const infinity = 253373432400000;

  const depthHashmap = {};
  const arr = [
    4, 9, 16, 36, 64, 144, 289, 576, 1156, 2601, 4624, 10404, 18496, 41616,
    166464,
  ];
  arr.forEach((i, idx) => (depthHashmap[idx] = i));

  const txTypeHashmap = {
    0: "mints",
    1: "trades",
    2: "all",
  };

  const dateRange = dateHashmap[date];
  const sectorCount = depthHashmap[depth];
  const txType = txTypeHashmap[selectedTxType];
  let dimLen = 314;
  const dataId = isAvgTab
    ? `${sectorCount}_${dateRange}`
    : `tx_${sectorCount}_${txType}_${dateRange}`;

  useEffect(() => {
    const dateToTs = {
      // [lt, gte]
      0: [infinity, getCurrentHour() - 1 * 60 * 60 * 1000],
      1: [infinity, getCurrentDay()],
      2: [getCurrentDay(), getCurrentDay() - 24 * 60 * 60 * 1000],
      3: [infinity, getCurrentDay() - 7 * 24 * 60 * 60 * 1000],
      4: [infinity, getCurrentDay() - 30 * 24 * 60 * 60 * 1000],
      5: [infinity, 0],
    };

    async function fetchAvg() {
      setLoading(true);
      let response = {};

      response = await app.currentUser.functions.getDCLHeatmapData(
        dateToTs[date]
      );
      // 163,158; max x and y
      // 314 + 308; max x and y for heatmap
      // 1, 0 min x and y for heatmap
      /* function range(size, startAt = 0) {
        return [...Array(size).keys()].map((i) => i + startAt);
      }
      const xRange = range(314, 1);
      const yRange = range(309, 0);
      var responseSample = [
        {
          id: 17, // y starts from 0
          data: [
            {
              x: 1, // x, starts from 1, no negative values
              y: 7153, // value or null
            },
          ],
        },
      ];
      response = response.map((i) => {
        // get x values from data
        const xValues = i.data.map((j) => j.x);
        // find xRange values that are not in xValues and add them to data
        const missingXValues = xRange.filter((j) => !xValues.includes(j));
        // add missing x values to data
        missingXValues.forEach((j) => {
          i.data.push({
            x: j,
            y: null,
          });
        });
        // sort data by x values ascending
        i.data.sort((a, b) => a.x - b.x);
        return i;
      });

      // add missing yRange values to response
      const missingYValues = yRange.filter(
        (i) => !response.map((j) => j.id).includes(i)
      );
      missingYValues.forEach((i) => {
        // create data object for each xRange
        const data = xRange.map((j) => {
          return {
            x: j,
            y: null,
          };
        });
        response.push({
          id: i,
          data: data,
        });
      });

      // sort response by id ascending
      response.sort((a, b) => a.id - b.id); */
      // y tepeden basliyor, 0 diyil max value kacsa o olcak
      // mesela y -150 + 150 arasi diyelim, + 150ye 0 diceksin, asagi gittikce articak, max noktasi da 300
      // 150 149 ... -149 -150
      // 0, 1, ... , 299, 300
      // 150 eksi bu yapicaksin. ama bu sinirlar net degil

      // 165, 164, ... -149, -150
      // 0, 1, ... , 314, 315
      // 165ten cikaricaz, max limitleri neyse.

      // ama x 1 ile baslamali, onu mesela 166dan cikaricaksin. // yok lan xe dokunma gardas

      // merge scene parcels into one parcel

      // add scene titles to coordinates
      /* response = response.map((i) => {
        const scene = sceneData.find((j) => j.id === i.id);
        if (scene) {
          i.title = scene.title;
          i.parcels = scene.parcels;
        }
        return i;
      }); */

      /* if (isAvgTab) {
        response = await app.currentUser.functions.get_heatmap_data(
          sectorCount,
          dateRange
        );
        // log first 5 items from response
        console.log("resp", response.slice(0, 5));
      } else {
        response = await app.currentUser.functions.get_heatmap_tx_data(
          sectorCount,
          txType,
          dateRange
        );
      } */

      // eski null doldurmak
      response.forEach((e) => {
        if (e.data.length === dimLen) return;

        const arr = [...Array(dimLen)];
        arr.forEach((_, i) => {
          let obj = e.data.find((o) => o.x === i + 1);
          if (obj === undefined) {
            e.data.push({ x: i + 1, y: null });
          }
        });
      });
      response.sort((a, b) => b.id - a.id);
      response.forEach((i) => i.data.sort((a, b) => a.x - b.x));

      // deciles
      let allValues = [];
      let deciles = [];
      response.forEach((i) => {
        i["data"].forEach((d) => {
          if (d.y != null) allValues.push(d.y);
        });
      });

      /*       // count item counts in allValues
      const counts = allValues.reduce((acc, cur) => {
        if (acc[cur]) {
          acc[cur]++;
        } else {
          acc[cur] = 1;
        }
        return acc;
      }, {});

      // log counts
      console.log("counts", counts); */

      //allValues = [...new Set(allValues)];

      allValues.sort((a, b) => a - b);
      // may add these in deciles
      // const minVal = allValues[0]
      // const maxVal = allValues[allValues.length - 1]
      const elevenRange = [...Array(11).keys()];
      elevenRange.forEach((i) => {
        var t =
          i < 9
            ? Math.floor((allValues.length / 10) * (i + 1)) - 1
            : Math.floor((allValues.length / 20) * (i + 1 + 9)) - 1;
        deciles.push(allValues[t]);
      });

      //  1, 2-5, 6-10,    11-50, 51+?
      deciles = [1, 5, 10, 50, 99999];

      //response.forEach((k, idx) => {
      //  response[0]["data"] = response[0]["data"].map((i) => {
      //    i.y = 1;
      //    return i;
      //  });
      //});

      /* [308].forEach((k, idx) => {
        response[k]["data"] = response[k]["data"].map((i, index) => {
          if (index < 999301) {
            i.y = 1;
          }
          return i;
        });
      }); */

      setData([...data, { id: dataId, data: response, deciles: deciles }]);
      setCurrentData({ data: response, deciles: deciles });
      setLoading(false);
    }

    const obj = data.find((o) => o.id === dataId);

    if (!obj) {
      fetchAvg();
    } else {
      setCurrentData({ data: obj.data, deciles: obj.deciles });
    }
  }, [
    app.currentUser.functions,
    data,
    dataId,
    date,
    dateRange,
    dimLen,
    isAvgTab,
    sectorCount,
    selectedTab,
    txType,
  ]);

  return !loading ? (
    <ResponsiveHeatMapCanvas
      data={currentData.data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      valueFormat={""}
      //valueFormat={isAvgTab ? "$," : ""}
      //valueFormat=">-.2s"
      colors={customColor} //;
      emptyColor="#1E1C32"
      opacity={0.7}
      activeOpacity={0.8}
      inactiveOpacity={0.7}
      enableLabels={false}
      hoverTarget="cell"
      tooltip={(i) => {
        const y = i.cell.serieId;
        const x = i.cell.data.x;
        //let sceneTitle = sceneData.find((j) => {
        //  var coordinates = `${x - 151},${158 - y}`;
        //  return j.parcels.includes(coordinates);
        //});
        // if sceneTitle.title is null assign None to sceneTitle
        let sceneTitle = sceneTitles[`${x - 151},${y - 151}`];
        sceneTitle = sceneTitle === undefined ? "-" : sceneTitle;
        //console.log("sceneTitle", sceneTitle);
        return DCLHeatmapCustomTooltip(i, dimLen, isAvgTab, sceneTitle); // sceneTitle
      }}
      animate={false}
      onClick={handleClick}
    />
  ) : (
    <div className="flex items-center justify-center h-screen">
      <div className="translate-x-[-75px]">
        <BounceLoader color={"#3892BB"} size={150} />
      </div>
    </div>
  );
};

export default DCLHeatmap;
