import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
//import DuneEmbed from "../partials/DuneEmbed";
import DuneAllWorldsEmbed from "../partials/DuneAllWorldsEmbed";

function EcosystemPage({ data, objName, type }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  var d = [
    "decentraland_land",
    "somnium_space",
    "byo_land",
    "sandbox",
    "netvrk",
    "fluf_world",
    "worldwide_webb",
    "treeverse",
  ];

  const tabOptions = {
    decentraland_land: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Land & Estates",
      },
      {
        id: 2,
        tab: "Wearables",
      },
      {
        id: 3,
        tab: "Names",
      },
    ],
    somnium_space: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Items",
      },
      {
        id: 3,
        tab: "Avatars",
      },
    ],
    byo_land: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Pills",
      },
      {
        id: 3,
        tab: "Apostles",
      },
      {
        id: 4,
        tab: "Crafts",
      },
    ],
    sandbox: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Assets",
      },
      {
        id: 3,
        tab: "Snoop Doggies",
      },
    ],
    netvrk: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Transports",
      },
      {
        id: 3,
        tab: "Bonuses",
      },
      {
        id: 4,
        tab: "Avatars",
      },
    ],
    fluf_world: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Thingies",
      },
      {
        id: 3,
        tab: "Scenes & Sounds",
      },
      {
        id: 4,
        tab: "Flufs",
      },
    ],
    worldwide_webb: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Items",
      },
    ],
    treeverse: [
      {
        id: 0,
        tab: "Total",
      },
      {
        id: 1,
        tab: "Lands",
      },
      {
        id: 2,
        tab: "Trees",
      },
      {
        id: 3,
        tab: "Timeless Characters",
      },
    ],
  };

  const tabMap = {
    decentraland_land: {
      0: "total",
      1: "land_and_estates",
      2: "wearables",
      3: "names",
    },
    somnium_space: {
      0: "total",
      1: "lands",
      2: "items",
      3: "avatars",
    },
    byo_land: {
      0: "total",
      1: "lands",
      2: "pills",
      3: "apostles",
      4: "crafts",
    },
    sandbox: {
      0: "total",
      1: "lands",
      2: "assets",
      3: "snoop_doggies",
    },
    netvrk: {
      0: "total",
      1: "lands",
      2: "transports",
      3: "bonuses",
      4: "avatars",
    },
    fluf_world: {
      0: "total",
      1: "lands",
      2: "thingies",
      3: "scenes_and_sounds",
      4: "flufs",
    },
    worldwide_webb: {
      0: "total",
      1: "lands",
      2: "items",
    },
    treeverse: {
      0: "total",
      1: "lands",
      2: "trees",
      3: "timeless_characters",
    },
  };

  const widths = {
    transactions: 12,
    worldRankings: 12,
    cumulativeSales: 2,
    pricePerMonth: 4,
    pricePerDay: 4,
    ownership: 4,
    value: 4,
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* tabs */}
            <div className="relative mb-8">
              <div
                className="absolute bottom-0 w-full h-px bg-slate-200"
                aria-hidden="true"
              ></div>
              <ul className="relative text-sm font-medium flex flex-nowrap -mx-8">
                {tabOptions[objName].map((tab) => {
                  return (
                    <button
                      key={tab.id}
                      className="mr-6 first:pl-8 last:mr-0"
                      onClick={() => {
                        setSelectedTab(tab.id);
                      }}
                    >
                      <div
                        className={`block pb-2 font-medium whitespace-nowrap ${
                          tab.id === selectedTab
                            ? "text-indigo-500 border-indigo-500 border-b-2"
                            : "text-slate-600"
                        }`}
                      >
                        {tab.tab}
                      </div>
                    </button>
                  );
                })}
              </ul>
            </div>
            <div className="grid grid-cols-12 gap-3 items-center">
              {/* Dune embeds */}
              {data[tabMap[objName][selectedTab]].map((i, idx) => (
                <DuneAllWorldsEmbed
                  key={tabMap[selectedTab] + idx}
                  title={i.title}
                  isTable={i.is_table}
                  src={i.embed_url}
                  width={12}
                />
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EcosystemPage;
