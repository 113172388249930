import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LogoCarouselData } from "../Constants/LogoScrollData";

export default function ScrollLogo() {
  const Option = {
    responsive: {
      0: {
        items: 1,
      },
      375: {
        items: 1,
      },
      425: {
        items: 2,
      },
      575: {
        items: 3,
      },
      770: {
        items: 4,
      },
      960: {
        items: 4,
      },
      1170: {
        items: 4,
      },
      1340: {
        items: 4,
      },
      1560: {
        items: 4,
      },
      1600: {
        items: 4,
      },
    },
  };
  return (
    <div className="developer-bg">
    
        <OwlCarousel
          className="owl-theme"
          autoplay={true}
          loop
          margin={40}
          autoplaySpeed={2000}
          autoplayTimeout={2000}
          
          nav={false}
          dots={false}
          items={6}
          
          responsive={Option.responsive}
        >
          {LogoCarouselData.map((x) => {
            return(
              <div className="item slider-item" key={x.id}>
            <img src={x.imgSrc} alt="developer1" />
          </div>
            )
          })}
          
        </OwlCarousel>
      </div>
  );
}
