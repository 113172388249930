import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Bar, Line } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";
import { useRealmApp } from "../RealmApp";
import { format } from "d3-format";
import { useMediaQuery } from "react-responsive";

export const background = "white";

const defaultMargin = { top: 20, left: 70, right: 30, bottom: 60 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "#FFECE8",
  color: "black",
};

const axisLabelProps = {
  fontFamily: "IBM Plex Sans",
  fontSize: 12,
  color: "black",
  textAnchor: "middle",
};

const dataLabelStyle = {
  fontFamily: "IBM Plex Sans",
  fontSize: 11,
  color: "black",
};

/*
const parseDate = timeParse("%Y-%m-%d");
const format = timeFormat("%b %d");
const formatDate = (date) => format(parseDate(date)); */

const formatDate = (type) => (timestamp) => {
  //const formatTime = timeFormat();
  //const day = timeFormat("%d");

  /* const ordinalSuffixes = ["th", "st", "nd", "rd"];
  function ordinalSuffix(number) {
    const value = number % 100;

    return (
      ordinalSuffixes[(value - 20) % 10] ||
      ordinalSuffixes[value] ||
      ordinalSuffixes[0]
    );
  } */
  /* if (type === "day") {
    const day = timeFormat("%d");
    return formatTime(date) + ordinalSuffix(+day(date));
  } */

  const dateFormats = {
    month: "%y-%m",
    week: "%y-%m-%d",
    day: "%b %d",
  };

  const date = new Date(timestamp);
  const formatTime = timeFormat(dateFormats[type]);

  return formatTime(date);
};

export default function BarChart({
  width,
  height,
  events = false,
  margin = defaultMargin,
  arg,
  isPortals = false,
  data2,
  label,
  avg_type,
}) {
  const app = useRealmApp();

  const [data, setData] = useState([]);

  const [xNumTicks, setXNumTicks] = useState(5);

  useEffect(() => {
    const isMd = width > 650;
    const isXl = width > 850;
    const is2xl = width > 1000;
    const is3xl = width > 1200;
    let numTicks = is3xl ? 19 : is2xl ? 17 : isXl ? 13 : isMd ? 9 : 6;
    setXNumTicks(numTicks);
  }, [width]);

  useEffect(() => {
    async function fetchAvg() {
      let response = await app.currentUser.functions.axie_test(arg);
      setData(response.result);
    }

    async function fetchAvgPortals() {
      let response = await app.currentUser.functions.portals_test(arg);
      setData(response.result);
    }

    async function fetchAvg2() {
      let response = await app.currentUser.functions.getChartData(arg);
      setData(response);
    }

    if (typeof arg == "object") {
      fetchAvg2();
    } else {
      if (isPortals) {
        fetchAvgPortals();
      } else {
        fetchAvg();
      }
    }
  }, [app.currentUser.functions, arg, data2, isPortals]);

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      scroll: true,
      detectBounds: true,
    }
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: "",
  });

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // accessors
  const getTimestamp = (d) => d.timestamp;
  const getAvgValue = (d) => Number(d.avg);
  const getDataValue = (d) => Number(d.data);
  const getValue = (d) => Number(d.value);
  const getTraitValue = (d) => Number(d.trait_value);

  const getSold = (d) => Number(d.soldCount);
  const getOrder = (d) => Number(d.order);

  const getOwners = (d) => Number(d.owners);

  const getNewOwners = (d) => d.newOwners;

  const formatUnits = (order) => {
    return order <= 4
      ? order + 1 + ""
      : order === 5
      ? "6-10"
      : order === 6
      ? "11-20"
      : order === 7
      ? "21-30"
      : order === 8
      ? "31-40"
      : order === 9
      ? "41-50"
      : order === 10
      ? "51-100"
      : order === 11
      ? "101-500"
      : order === 12
      ? "501-1000"
      : "1000+";
  };

  const yNumTicks = 3;

  //const isSm = useMediaQuery({ query: '(min-width: 640px)' })
  //const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  //const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  //const is2xl = useMediaQuery({ query: "(min-width: 1536px)" });

  // const xNumTicks = is2xl ? 18 : isXl ? 13 : isMd ? 9 : 7;

  const metaData = useMemo(() => {
    const traitFormatter = (id) => {
      const idToTypeMap = {
        decentraland: {
          "Distance to Plaza": {
            0: "1",
            1: "2",
            2: "3",
            3: "4",
            4: "5",
            5: "6",
            6: "7",
            7: "8",
            8: "9",
          },
          "Distance to Road": {
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
          },
        },
        somniumSpace: {
          "Parcel size": {
            0: "S",
            1: "M",
            2: "XL",
          },
          waterfront_status: {
            0: "non Waterfront",
            1: "Waterfront",
          },
          roadside_status: {
            0: "non Roadside",
            1: "Roadside",
          },
        },
        worldwideWebb: {
          type: {
            0: "small apartment",
            1: "medium apartment",
            2: "large apartment",
            3: "penthouse",
          },
        },
        byoLand: {
          Zone: {
            0: "Volcan",
            1: "Atlas Mountains",
            2: "Radioactive Swamp",
            3: "The Harbor",
            4: "Desolate Dunes",
            5: "Lumia",
            6: "Amazonia",
            7: "Bone Valley",
            8: "No Man's Land",
            9: "The Mine",
            10: "Nebula",
            11: "Astroverse",
            12: "Frigid Plains",
            13: "Cloud10",
            14: "Komo Caves",
            15: "Oblivion Void",
          },
          Abundancy: {
            0: "Occasional",
            1: "Frequent",
            2: "Abundant",
            3: "Dominant",
          },
        },
      };

      return idToTypeMap[arg.world][arg.name][id];
    };

    // This could be implemented better, maybe with changing values per inside keys
    const metaDataKeys = {
      1: {
        accessors: { x: getTimestamp, y: getAvgValue },
        yAxisLabelText: "Avg Price (USD)",
        xAxisLabelText: "Month",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Average",
        showDataLabels: true,
      },
      2: {
        accessors: { x: getTimestamp, y: getAvgValue },
        yAxisLabelText: "Avg Price (USD)",
        xAxisLabelText: "Week",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("week"),
        tooltipLabelFormat: formatDate("week"),
        tooltipLabel: "Average",
        showDataLabels: false,
      },
      3: {
        accessors: { x: getTimestamp, y: getAvgValue },
        yAxisLabelText: "Avg Price (USD)",
        xAxisLabelText: "Day",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("day"),
        tooltipLabelFormat: formatDate("day"),
        tooltipLabel: "Average",
        showDataLabels: true,
      },
      4: {
        accessors: { x: getTimestamp, y: getValue },
        yAxisLabelText: "Value (USD)",
        xAxisLabelText: "Month",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Average",
        showDataLabels: true,
      },
      5: {
        accessors: { x: getTimestamp, y: getValue },
        yAxisLabelText: "Value (USD)",
        xAxisLabelText: "Month",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Average",
        showDataLabels: true,
      },
      6: {
        accessors: { x: getTimestamp, y: getValue },
        yAxisLabelText: "Value (USD)",
        xAxisLabelText: "Week",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("week"),
        tooltipLabelFormat: formatDate("week"),
        tooltipLabel: "Average",
        showDataLabels: false,
      },
      7: {
        accessors: { x: getTimestamp, y: getValue },
        yAxisLabelText: "Value (USD)",
        xAxisLabelText: "Day",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("day"),
        tooltipLabelFormat: formatDate("day"),
        tooltipLabel: "Average",
        showDataLabels: true,
      },
      8: {
        accessors: { x: getTimestamp, y: getSold },
        yAxisLabelText: "Units",
        xAxisLabelText: "Month",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Units",
        showDataLabels: true,
      },
      9: {
        accessors: { x: getTimestamp, y: getSold },
        yAxisLabelText: "Units",
        xAxisLabelText: "Month",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Units",
        showDataLabels: true,
      },
      10: {
        accessors: { x: getTimestamp, y: getSold },
        yAxisLabelText: "Units",
        xAxisLabelText: "Week",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("week"),
        tooltipLabelFormat: formatDate("week"),
        tooltipLabel: "Units",
        showDataLabels: false,
      },
      11: {
        accessors: { x: getTimestamp, y: getSold },
        yAxisLabelText: "Units",
        xAxisLabelText: "Day",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("day"),
        tooltipLabelFormat: formatDate("day"),
        tooltipLabel: "Units",
        showDataLabels: true,
      },
      12: {
        accessors: { x: getTimestamp, y: getNewOwners },
        yAxisLabelText: "Owners",
        xAxisLabelText: "Month",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Owners",
        showDataLabels: true,
      },
      13: {
        accessors: { x: getTimestamp, y: getNewOwners },
        yAxisLabelText: "Owners",
        xAxisLabelText: "Week",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("week"),
        tooltipLabelFormat: formatDate("week"),
        tooltipLabel: "Owners",
        showDataLabels: false,
      },
      14: {
        accessors: { x: getTimestamp, y: getNewOwners },
        yAxisLabelText: "Owners",
        xAxisLabelText: "Day",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("day"),
        tooltipLabelFormat: formatDate("day"),
        tooltipLabel: "Owners",
        showDataLabels: true,
      },
      15: {
        accessors: { x: getOrder, y: getOwners },
        yAxisLabelText: "Owners",
        xAxisLabelText: "Number of units owned",
        dataLabelFormat: ",",
        yAxisFormat: "~s",
        xAxisFormat: formatUnits,
        tooltipLabelFormat: formatUnits,
        tooltipLabel: "Owners",
        showDataLabels: true,
      },
      [arg.chartType]: {
        accessors: { x: getTraitValue, y: getDataValue },
        yAxisLabelText: `${
          typeof arg == "object"
            ? arg.chartType.toLowerCase().includes("median")
              ? "Median"
              : "Mean"
            : ""
        } Avg Price (USD)`,
        xAxisLabelText:
          arg.name === "waterfront_status" ? "Waterfront" : arg.name,
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: traitFormatter,
        tooltipLabelFormat: traitFormatter,
        tooltipLabel: "Average",
        showDataLabels: true,
        strokeColors: {
          0: "red",
          1: "#005ADC",
          2: "MediumTurquoise",
          3: "green",
          4: "orange",
          5: "purple",
          6: "khaki",
          7: "indigo",
          8: "pink",
          9: "powderblue",
          10: "navy",
          11: "YellowGreen",
          12: "SaddleBrown",
          13: "PaleVioletRed",
          14: "OliveDrab",
          15: "MediumAquaMarine",
          16: "LightSalmon",
          17: "IndianRed",
          18: "DarkKhaki",
        },
      },
    };

    return typeof arg == "object"
      ? metaDataKeys[arg.chartType]
      : metaDataKeys[arg];
  }, [arg]);

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: data.map(metaData.accessors.x),
        padding: 0.1,
      }),
    [metaData.accessors.x, data, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(metaData.accessors.y))],
      }),
    [metaData.accessors.y, data, yMax]
  );

  const handleTooltip = useCallback(
    (event) => {
      let { x } = localPoint(event) || { x: defaultMargin.left };
      // idk why I needed to do this. above line was like this || { x: defaultMargin.left };
      x = x - defaultMargin.left;
      const bisect = bisector(metaData.accessors.x).left;

      // custom invert function
      /* 
      
      I tried this one before, but it doesn't consider padding I think

      xScale.invert = (function(){
        var domain = xScale.domain()
        var range = xScale.range()
        var scale = scaleQuantize().domain(range).range(domain)

        return function(x){
            return scale(x)
        }
      })()
      */

      xScale.invert = (function () {
        var domain = xScale.domain();
        var paddingOuter = xScale(domain[0]);
        var eachBand = xScale.step();

        return function (value) {
          var index = Math.floor((value - paddingOuter) / eachBand);
          return domain[Math.max(0, Math.min(index, domain.length - 1))];
        };
      })();

      const nearestValue = xScale.invert(x);
      const nearestValueIndex = bisect(data, nearestValue, 1);
      const d0 = data[nearestValueIndex - 1];
      const d1 = data[nearestValueIndex];
      let nearestDatum = d0;
      if (d1 && metaData.accessors.x(d1)) {
        nearestDatum =
          nearestValue.valueOf() - metaData.accessors.x(d0).valueOf() >
          metaData.accessors.x(d1).valueOf() - nearestValue.valueOf()
            ? d1
            : d0;
      }

      const containerX =
        ("clientX" in event ? event.clientX : 0) - containerBounds.left;
      const containerY =
        ("clientY" in event ? event.clientY : 0) - containerBounds.top;

      showTooltip({
        tooltipData: nearestDatum,
        // if this value is x, it will mirror the pointer event position
        // by finding the location of the nearest data point,
        // it appears to "snap" to nearest point
        tooltipLeft: containerX, //xScale(getLetter(nearestDatum)),
        tooltipTop: containerY, //yScale(getLetterFrequency(nearestDatum))
      });
    },
    [metaData.accessors, containerBounds, data, showTooltip, xScale]
  );

  return width < 10 ? null : (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            scale={yScale}
            width={xMax}
            stroke="#f2f2f2"
            shapeRendering="crispEdges"
            columns={false}
            //key={`grid-${'animationTrajectory'}`} // force animate on update
            //animationTrajectory={animationTrajectory}
            numTicks={yNumTicks}
            strokeOpacity={1}
          />
          {/* Bottom Axis Line */}
          <Line
            from={{ x: 0, y: yMax }}
            to={{ x: xMax, y: yMax }}
            stroke="black"
            shapeRendering="crispEdges"
            strokeWidth={1}
            pointerEvents="none"
          />
          <AxisBottom
            top={yMax}
            scale={xScale}
            label={metaData.xAxisLabelText}
            tickFormat={metaData.xAxisFormat}
            stroke={"black"}
            strokeWidth={1}
            numTicks={xNumTicks}
            hideTicks
            hideAxisLine
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            tickLabelProps={() => ({ ...axisLabelProps, fontSize: 10 })}
          />
          <AxisLeft
            numTicks={yNumTicks}
            scale={yScale}
            label={metaData.yAxisLabelText}
            tickFormat={(i) => format(metaData.yAxisFormat)(i)}
            stroke={"black"}
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            hideTicks
            hideAxisLine
            tickLabelProps={() => axisLabelProps}
          />

          <Group>
            {data.map((d) => {
              const xValue = metaData.accessors.x(d);
              const barWidth = xScale.bandwidth();

              const barHeight = yMax - (yScale(metaData.accessors.y(d)) ?? 0);
              const barX = xScale(xValue);
              const barY = yMax - barHeight;
              return (
                <Group key={`group-${xValue}`}>
                  <Bar
                    //key={`bar-${xValue}`}
                    x={barX}
                    y={barY}
                    width={barWidth} // 40
                    height={barHeight}
                    fill={arg < 24 ? "#005ADC" : metaData.strokeColors[xValue]}
                  />

                  {metaData.showDataLabels && (
                    <text
                      x={barX + barWidth / 2}
                      y={yMax - barHeight}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="black"
                      fontSize={12}
                      //dx={15}
                      dy={"-.53em"}
                      style={dataLabelStyle}
                    >
                      {format(metaData.dataLabelFormat)(
                        metaData.accessors.y(d)
                      )}
                    </text>
                  )}
                </Group>
              );
            })}
          </Group>
          <rect
            onPointerMove={handleTooltip}
            onMouseLeave={() => {
              hideTooltip();
            }}
            width={xMax}
            height={yMax}
            fill="transparent"
          />
          {tooltipData && (
            <g>
              {/* Crosshair lines */}
              <Line
                from={{ x: tooltipLeft - margin.left, y: 0 }}
                to={{ x: tooltipLeft - margin.left, y: yMax }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: tooltipTop - margin.top }}
                to={{ x: xMax, y: tooltipTop - margin.top }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </Group>
      </svg>

      {tooltipOpen && tooltipData && (
        <>
          <TooltipInPortal
            key={Math.random()} // needed for bounds to update correctly
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <div
              className="py-1.5 px-2"
              style={{
                fontFamily: "IBM Plex Sans",
                fontSize: 13,
                color: "black",
              }}
            >
              {/** date */}
              <h1
                style={{
                  fontWeight: "500",
                }}
              >
                {metaData.tooltipLabelFormat(metaData.accessors.x(tooltipData))}
              </h1>

              <ul className="first:mt-4">
                <li className="min-w-[14rem] grid grid-cols-[auto_auto_1fr_auto] gap-2 mt-2 text-right">
                  <span>
                    <svg
                      stroke={
                        arg < 24
                          ? "#005ADC"
                          : metaData.strokeColors[
                              metaData.accessors.x(tooltipData)
                            ]
                      }
                      fill={
                        arg < 24
                          ? "#005ADC"
                          : metaData.strokeColors[
                              metaData.accessors.x(tooltipData)
                            ]
                      }
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      icon="circle-fill"
                      aria-hidden="true"
                      alt="Bar Color"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8"></circle>
                    </svg>
                  </span>
                  <span>{metaData.tooltipLabel}</span>
                  <span>
                    {/* Value */}
                    {format(metaData.dataLabelFormat)(
                      metaData.accessors.y(tooltipData)
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </TooltipInPortal>
        </>
      )}
    </>
  );
}
