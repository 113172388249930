import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DuneEmbed from "../partials/DuneEmbed";
import * as Dashboards from "../embedData";
import * as Realm from "realm-web";
import { useRealmApp } from "../RealmApp";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Login() {
  const data = Dashboards.community_total_market.sandbox;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const app = useRealmApp();
  // Toggle between logging users in and registering new users
  // Keep track of form input state
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  // Keep track of input validation/errors
  const [error, setError] = React.useState({});
  // Whenever the mode changes, clear the form inputs
  React.useEffect(() => {
    setEmail("");
    setPassword("");
    setError({});
  }, []);

  //const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const handleLogin = async () => {
    //setIsLoggingIn(true);
    //setError((e) => ({ ...e, password: null }));
    //console.log("trying", email, password);

    try {
      await app.logIn(Realm.Credentials.emailPassword(email, password));
    } catch (err) {
      //console.log("err", err);
      handleAuthenticationError(err, setError);
    }
  };

  return (
    <>
      {app.currentUser ? (
        <Redirect exact to="/summary/mm10-index" />
      ) : (
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-96 my-6 mx-auto max-w-sm bg-white border border-slate-300 rounded p-4">
                <h1 className="text-3xl text-gray-800 font-bold im">Log in</h1>
                {Object.keys(error).length !== 0 && (
                  <div>
                    <div className="mt-2 inline-flex min-w-[20rem] px-2 py-2 rounded-sm text-sm bg-rose-100 border border-rose-200 text-rose-600">
                      <div className="flex w-full justify-between items-start">
                        <div className="flex">
                          <svg
                            className="w-4 h-4 shrink-0 fill-current opacity-80 m-[3px] mr-3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"></path>
                          </svg>
                          <div>
                            Details not recognised. Contact{" "}
                            <a href="mailto:hi@metametriks.com">
                              hi@metametriks.com
                            </a>{" "}
                            for support
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <div className="mt-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      className="border-slate-200 w-full px-3/4 py-1/2 leading-5 ring-0 drop-shadow rounded"
                      id="email"
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      state={error.email ? "error" : true ? "valid" : "none"}
                      //errorMessage={error.email}
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="border-slate-200 w-full px-3/4 py-1/2 leading-5 ring-0 drop-shadow rounded"
                      id="password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      state={
                        error.password
                          ? "error"
                          : error.password
                          ? "valid"
                          : "none"
                      }
                      //errorMessage={error.password}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end mt-6">
                  <button
                    className="btn bg-indigo-500 text-white ml-3"
                    onClick={() => handleLogin()}
                  >
                    Log in
                  </button>
                </div>
              </div>

              <div className="fixed max-w-[82.5%] px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto">
                {/*         <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        > */}
                {/* Cards */}
                <div className="overflow-hidden blur pointer-events-none grid grid-cols-12 gap-6 items-center">
                  {/* Dune embeds */}
                  {data.map((i) => (
                    <DuneEmbed
                      key={i.title}
                      title={i.title}
                      isTable={i.is_table}
                      src={i.embed_url}
                    />
                  ))}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case "invalid username":
      setError((prevErr) => ({ ...prevErr, email: "Invalid email address." }));
      break;
    case "invalid username/password":
    case "invalid password":
    case "401":
      setError((err) => ({ ...err, password: "Incorrect password." }));
      break;
    case "name already in use":
    case "409":
      setError((err) => ({ ...err, email: "Email is already registered." }));
      break;
    case "password must be between 6 and 128 characters":
    case "400":
      setError((err) => ({
        ...err,
        password: "Password must be between 6 and 128 characters.",
      }));
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}
