import React from "react";

import { useRealmApp } from "../../RealmApp";
import Table from "../../charts/Table";

function AxieTopOwnersTableWrapper({ arg, title, isPortals }) {
  const app = useRealmApp();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const truncate = (val) =>
    val.length >= 15 ? val.slice(0, 6) + "..." + val.slice(-4) : val;

  const columns = React.useMemo(() => {
    const priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return [
      {
        Header: "Owner",
        accessor: "_id",
        Cell: ({ row, value }) => (
          <a
            href={isPortals ? `https://solscan.io/account/${value}` : `https://explorer.roninchain.com/address/${value.replace('0x', 'ronin:')}`}
            target="_blank"
            rel="noreferrer"
            title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {row.original.ens_name != null
              ? row.original.ens_name
              : truncate(value)}
          </a>
        ),
      },
      {
        Header: "Bought",
        accessor: "bought",
        /* Cell: ({ row, value }) => ()), */
      },
      {
        Header: "Sold",
        accessor: "sold",
        /* Cell: ({ row, value }) => ()), */
      },
      {
        Header: "Net Units",
        accessor: "net_units",
        /* Cell: ({ row, value }) => ()), */
      },
      {
        Header: "Paid",
        accessor: "paid",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
      {
        Header: "Recieved",
        accessor: "recieved",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
      {
        Header: "Net",
        accessor: "net",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
    ];
  }, [isPortals]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      async function fetchAPI() {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          let response = await app.currentUser.functions.axie_test(
            arg,
            pageIndex,
            pageSize
          );
          let data = response["result"][0];

          setPageCount(Math.ceil(data.metadata.total / pageSize));
          setTotalCount(data.metadata.total);
          data = data.data;
          setData(data);
          setLoading(false);
        }
      }
      async function fetchPortalsAPI() {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          let response = await app.currentUser.functions.portals_test(
            arg,
            pageIndex,
            pageSize
          );
          let data = response["result"][0];

          setPageCount(Math.ceil(data.metadata.total / pageSize));
          setTotalCount(data.metadata.total);
          data = data.data;
          setData(data);
          setLoading(false);
        }
      }

      if (isPortals) {
        fetchPortalsAPI();
      } else {
        fetchAPI();
      }
    },
    [app.currentUser.functions, arg, isPortals]
  );

  return (
    <Table
      title={title}
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      tablePageSize={10}
      totalCount={totalCount}
    />
  );
}

export default AxieTopOwnersTableWrapper;
