import React from "react";
import { opportunityData } from "../Constants/OpportunityData";

export const Opportunity = () => {
  return (
    <>
      <div className="opportunity-container">
        <div className="opportunity-inner-container">
          <h1>Benefits of a Pro subscription </h1>
          <ul>
            {opportunityData.map((x) => {
              return (
                
                  <li key={x.id}>
                    <p>{x.text}</p>
                  </li>
                
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
