import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Line, LinePath } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleLinear, scaleTime } from "@visx/scale";
import { timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { extent, bisector } from "d3-array";
import { useRealmApp } from "../RealmApp";
import { format } from "d3-format";
import { useMediaQuery } from "react-responsive";
import { curveNatural } from "@visx/curve";

export const background = "white";

const defaultMargin = { top: 20, left: 70, right: 30, bottom: 60 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "#FFECE8",
  color: "black",
};

const axisLabelProps = {
  fontFamily: "IBM Plex Sans",
  fontSize: 12,
  color: "black",
  textAnchor: "middle",
};

/*
const dataLabelStyle = {
  fontFamily: "IBM Plex Sans",
  fontSize: 11,
  color: "black",
};
*/

/*
const parseDate = timeParse("%Y-%m-%d");
const format = timeFormat("%b %d");
const formatDate = (date) => format(parseDate(date)); */

const formatDate = (type) => (timestamp) => {
  //const formatTime = timeFormat();
  //const day = timeFormat("%d");

  /* const ordinalSuffixes = ["th", "st", "nd", "rd"];
  function ordinalSuffix(number) {
    const value = number % 100;

    return (
      ordinalSuffixes[(value - 20) % 10] ||
      ordinalSuffixes[value] ||
      ordinalSuffixes[0]
    );
  } */
  /* if (type === "day") {
    const day = timeFormat("%d");
    return formatTime(date) + ordinalSuffix(+day(date));
  } */

  const dateFormats = {
    month: "%y-%m",
    week: "%y-%m-%d",
    day: "%b %d",
    fullDate: "%Y-%m-%d",
  };

  const date = new Date(timestamp);
  const formatTime = timeFormat(dateFormats[type]);

  return formatTime(date);
};

export default function MultiLineChartDCL({
  width,
  height,
  events = false,
  margin = defaultMargin,
  arg,
  avg_type,
}) {
  const app = useRealmApp();

  const [data, setData] = useState([]);

  const [xNumTicks, setXNumTicks] = useState(5);

  useEffect(() => {
    const isMd = width > 650;
    const isXl = width > 850;
    const is2xl = width > 1000;
    const is3xl = width > 1200;
    let numTicks = is3xl ? 19 : is2xl ? 17 : isXl ? 13 : isMd ? 9 : 6;
    setXNumTicks(numTicks);
  }, [width]);

  useEffect(() => {
    async function fetchAvg() {
      let response = await app.currentUser.functions.getChartData(arg);
      setData(response);
    }

    fetchAvg();
  }, [app.currentUser.functions, arg]);

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      scroll: true,
      detectBounds: true,
    }
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: "",
  });

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // accessors
  const getTimestamp = (d) => d.timestamp;
  const getAvgValue = (d) => Number(d.avg);

  const yNumTicks = 3;

  //const isSm = useMediaQuery({ query: '(min-width: 640px)' })
  // const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  // const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  // const is2xl = useMediaQuery({ query: "(min-width: 1536px)" });

  // const xNumTicks = is2xl ? 18 : isXl ? 13 : isMd ? 9 : 7;

  const traitFormatter = (id) => {
    const idToTypeMap = {
      decentraland: {
        "Distance to Plaza": {
          0: "1",
          1: "2",
          2: "3",
          3: "4",
          4: "5",
          5: "6",
          6: "7",
          7: "8",
          8: "9",
        },
        "Distance to Road": {
          0: "0",
          1: "1",
          2: "2",
          3: "3",
          4: "4",
          5: "5",
          6: "6",
          7: "7",
          8: "8",
          9: "9",
        },
      },
      somniumSpace: {
        "Parcel size": {
          0: "S",
          1: "M",
          2: "XL",
        },
        waterfront_status: {
          0: "non Waterfront",
          1: "Waterfront",
        },
        roadside_status: {
          0: "non Roadside",
          1: "Roadside",
        },
      },
      worldwideWebb: {
        type: {
          0: "Small Apartment",
          1: "Medium Apartment",
          2: "Large Apartment",
          3: "Penthouse",
        },
      },
      byoLand: {
        Zone: {
          0: "Volcan",
          1: "Atlas Mountains",
          2: "Radioactive Swamp",
          3: "The Harbor",
          4: "Desolate Dunes",
          5: "Lumia",
          6: "Amazonia",
          7: "Bone Valley",
          8: "No Man's Land",
          9: "The Mine",
          10: "Nebula",
          11: "Astroverse",
          12: "Frigid Plains",
          13: "Cloud10",
          14: "Komo Caves",
          15: "Oblivion Void",
        },
        Abundancy: {
          0: "Occasional",
          1: "Frequent",
          2: "Abundant",
          3: "Dominant",
        },
      },
    };

    return idToTypeMap[arg.world][arg.name][id];
  };

  const metaData = useMemo(() => {
    // This could be implemented better, maybe with changing values per inside keys
    const metaDataKeys = {
      1: {
        accessors: { x: getTimestamp, y: getAvgValue },
        yAxisLabelText: `${
          arg.chartType.toLowerCase().includes("median") ? "Median" : "Mean"
        } Avg Price (USD)`,
        xAxisLabelText: "Month",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("month"),
        tooltipLabelFormat: formatDate("month"),
        tooltipLabel: "Average",
        showDataLabels: true,
        strokeColors: {
          0: "red",
          1: "#005ADC",
          2: "MediumTurquoise",
          3: "green",
          4: "orange",
          5: "purple",
          6: "khaki",
          7: "indigo",
          8: "pink",
          9: "powderblue",
          10: "navy",
          11: "YellowGreen",
          12: "SaddleBrown",
          13: "PaleVioletRed",
          14: "OliveDrab",
          15: "MediumAquaMarine",
          16: "LightSalmon",
          17: "IndianRed",
          18: "DarkKhaki",
        },
      },
    };

    return metaDataKeys[1];
  }, [arg.chartType]);

  // scales, memoize for performance
  const allData = data.map((i) => i.data).reduce((rec, d) => rec.concat(d), []);

  const xScale = useMemo(
    () =>
      scaleTime({
        // A temprorary solution for timezone issue.
        // It's not showing first month's label on +x timezones
        // Subtracted 24 hours to fix this.
        domain: [
          extent(allData, metaData.accessors.x)[0] - 24 * 60 * 60 * 1000,
          extent(allData, metaData.accessors.x)[1],
        ],
        range: [0 + 20, xMax - 20],
      }),
    [metaData.accessors.x, allData, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, extent(allData, metaData.accessors.y)[1]], // will fix this
      }),
    [metaData.accessors.y, allData, yMax]
  );

  const handleTooltip = useCallback(
    (event) => {
      let { x } = localPoint(event) || { x: defaultMargin.left };
      // idk why I needed to do this. above line was like this || { x: defaultMargin.left };
      x = x - defaultMargin.left;
      const bisect = bisector(metaData.accessors.x).left;

      const getNearestDatum = (data) => {
        const nearestValue = xScale.invert(x);
        const nearestValueIndex = bisect(data, nearestValue, 1);
        const d0 = data[nearestValueIndex - 1];
        const d1 = data[nearestValueIndex];
        let nearestDatum = d0;
        if (d1 && metaData.accessors.x(d1)) {
          nearestDatum =
            nearestValue.valueOf() - metaData.accessors.x(d0).valueOf() >
            metaData.accessors.x(d1).valueOf() - nearestValue.valueOf()
              ? d1
              : d0;
        }
        return nearestDatum;
      };

      const nearestDatums = data.map((i) => {
        const nd = getNearestDatum(i.data);
        return { ...nd, trait_value: i["trait_value"] };
      });

      const containerX =
        ("clientX" in event ? event.clientX : 0) - containerBounds.left;
      const containerY =
        ("clientY" in event ? event.clientY : 0) - containerBounds.top;

      showTooltip({
        tooltipData: nearestDatums,
        // if this value is x, it will mirror the pointer event position
        // by finding the location of the nearest data point,
        // it appears to "snap" to nearest point
        tooltipLeft: containerX, //xScale(getLetter(nearestDatum)),
        tooltipTop: containerY, //yScale(getLetterFrequency(nearestDatum))
      });
    },
    [metaData.accessors, containerBounds, data, showTooltip, xScale]
  );

  return width < 10 ? null : (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            scale={yScale}
            width={xMax}
            stroke="#f2f2f2"
            shapeRendering="crispEdges"
            columns={false}
            //key={`grid-${'animationTrajectory'}`} // force animate on update
            //animationTrajectory={animationTrajectory}
            numTicks={yNumTicks}
            strokeOpacity={1}
          />
          {/* Bottom Axis Line */}
          <Line
            from={{ x: 0, y: yMax }}
            to={{ x: xMax, y: yMax }}
            stroke="black"
            shapeRendering="crispEdges"
            strokeWidth={1}
            pointerEvents="none"
          />
          <AxisBottom
            top={yMax}
            scale={xScale}
            label={metaData.xAxisLabelText}
            tickFormat={metaData.xAxisFormat}
            stroke={"black"}
            strokeWidth={1}
            numTicks={xNumTicks}
            hideTicks
            hideAxisLine
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            tickLabelProps={() => ({ ...axisLabelProps, fontSize: 10 })}
          />
          <AxisLeft
            numTicks={yNumTicks}
            scale={yScale}
            label={metaData.yAxisLabelText}
            tickFormat={(i) => format(metaData.yAxisFormat)(i)}
            stroke={"black"}
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            hideTicks
            hideAxisLine
            tickLabelProps={() => axisLabelProps}
          />
          {data.map((i, idx) => {
            return (
              <>
                <LinePath
                  curve={curveNatural}
                  key={idx}
                  stroke={metaData.strokeColors[i.trait_value]}
                  strokeWidth={2}
                  data={i.data}
                  defined={(d) => {
                    return d.avg !== null ? true : false;
                  }}
                  x={(d) => xScale(metaData.accessors.x(d)) ?? 0}
                  y={(d) => yScale(metaData.accessors.y(d)) ?? 0}
                />
                {i.data.map((d, dId) =>
                  d.avg !== null ? (
                    <circle
                      key={dId}
                      r={3}
                      cx={xScale(metaData.accessors.x(d))}
                      cy={yScale(metaData.accessors.y(d))}
                      stroke={metaData.strokeColors[i.trait_value]}
                      fill={metaData.strokeColors[i.trait_value]}
                    />
                  ) : null
                )}
              </>
            );
          })}
          <rect
            onPointerMove={handleTooltip}
            onMouseLeave={() => {
              hideTooltip();
            }}
            width={xMax}
            height={yMax}
            fill="transparent"
          />
          {tooltipData && (
            <g>
              {/* Crosshair lines */}
              <Line
                from={{ x: tooltipLeft - margin.left, y: 0 }}
                to={{ x: tooltipLeft - margin.left, y: yMax }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: tooltipTop - margin.top }}
                to={{ x: xMax, y: tooltipTop - margin.top }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </Group>
      </svg>

      {tooltipOpen && tooltipData.length > 0 && (
        <>
          <TooltipInPortal
            key={Math.random()} // needed for bounds to update correctly
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <div
              className="py-1.5 px-2"
              style={{
                fontFamily: "IBM Plex Sans",
                fontSize: 13,
                color: "black",
              }}
            >
              {/** date */}
              <h1
                style={{
                  fontWeight: "500",
                }}
              >
                {metaData.tooltipLabelFormat(
                  metaData.accessors.x(tooltipData[1])
                )}
              </h1>
              {/*
              arg.name doesn't always match with beautified name
              <h1 className="mt-1">{arg.name}</h1> 
              */}
              {tooltipData.map((i, idx) => (
                <>
                  <ul className="first:mt-4">
                    <li className="min-w-[14rem] grid grid-cols-[auto_auto_1fr_auto] gap-2 mt-2 text-right">
                      <span>
                        <svg
                          stroke={metaData.strokeColors[i.trait_value]}
                          fill={metaData.strokeColors[i.trait_value]}
                          strokeWidth="0"
                          viewBox="0 0 16 16"
                          icon="circle-fill"
                          aria-hidden="true"
                          alt="Bar Color"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="8" cy="8" r="8"></circle>
                        </svg>
                      </span>
                      <span>
                        {
                          traitFormatter(
                            i.trait_value
                          ) /* metaData.tooltipLabel */
                        }
                      </span>
                      <span>
                        {/* Value */}
                        {format(metaData.dataLabelFormat)(
                          metaData.accessors.y(i)
                        )}
                      </span>
                    </li>
                  </ul>
                </>
              ))}
            </div>
          </TooltipInPortal>
        </>
      )}
    </>
  );
}
