import React, { useEffect } from "react";

import { useRealmApp } from "../../RealmApp";
import Table from "../../charts/Table";


function AxieTableWrapper({title, isPortals}) {

  const app = useRealmApp();

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const truncate = val => val.length >= 15 ? (val.slice(0, 6) + "..." + val.slice(-4)) : val

  const columns = React.useMemo(
    () => {
      const formatter = new Intl.NumberFormat('en-US')
      const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    
      return [
      {
        Header: 'Wallet',
        accessor: "_id",
        Cell: ({ row, value }) => (
          <a
            href={isPortals ? `https://solscan.io/account/${value}` : `https://explorer.roninchain.com/address/${value.replace('0x', 'ronin:')}`}
            target='_blank' rel='noreferrer' title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {row.original.ens_name != null ? row.original.ens_name : truncate(value)}
          </a>
        )
      },
      {
        Header: "Units",
        accessor: "count",
        Cell: ({ value }) => String(formatter.format(value))
      },
      {
        Header: "30d Change",
        accessor: "1mchange",
        Cell: ({ row, value }) => String(value === null ? ("+" + row.original.count) : value <= 0 ? value : ("+" + value))
      },
      {
        Header: "3m Change",
        accessor: "3mchange",
        Cell: ({ row, value }) => String(value === null ? ("+" + row.original.count) : value <= 0 ? value : ("+" + value))
      },
      {
        Header: "12m Change",
        accessor: "12mchange",
        Cell: ({ row, value }) => String(value === null ? ("+" + row.original.count) : value <= 0 ? value : ("+" + value))
      },
      {
        Header: "% of Current Units",
        accessor: "percent_of_current_supply",
        Cell: ({ value }) => String((100 * value).toFixed(2) + "%")
      },
      {
        Header: "% of Total Units",
        accessor: "percent_of_total_supply",
        Cell: ({ value }) => String((100 * value).toFixed(2) + "%")
      },
      {
        Header: "Portfolio Value",
        accessor: "portfolio_value",
        Cell: ({ value }) => String(priceFormatter.format(Math.round(value)))
      }
    ]},
    [isPortals]
  );

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    async function fetchAPI() {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {

        let response;
        if (isPortals) {
          response = await app.currentUser.functions.get_owners_portals(pageIndex, pageSize, 1);
        } else {
          response = await app.currentUser.functions.get_owners_axie(pageIndex, pageSize, 1);
        }

        let data = response['result'][0]

        setPageCount(Math.ceil(data.metadata.total / pageSize))
        setTotalCount(data.metadata.total);

        data = data.data

        setData(data);
        setLoading(false)
      }
    }

    fetchAPI();

  }, [app.currentUser.functions, isPortals])

  useEffect(() => {

  }, []);

  return (
    <Table
      title={title}
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      tablePageSize={10}
      totalCount={totalCount}
    />
  );
}

export default AxieTableWrapper;
