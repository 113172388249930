import React from "react";
import { useTable, usePagination } from "react-table";

function Table({
  columns,
  data,
  title,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  tablePageSize,
  totalCount
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    //pageOptions,
    //pageCount,
    //gotoPage,
    nextPage,
    previousPage,
    //setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0,  pageSize: tablePageSize}, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  // <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200" >
  return (

    <div className="col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200 overflow-scroll">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800 text-sm">{title}</h2>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto"></div>
        <table {...getTableProps()} className="table-auto w-full">
          <thead className="text-xs text-gray-600 bg-gray-50 rounded-sm">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="">
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className="p-2"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-xs font-semibold divide-y divide-gray-100">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-2 text-center"
                      >
                        <div>
                          {cell.render("Cell")}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className="mt-2 px-6 py-2.5 bg-slate-50 border border-slate-200 rounded-sm">
          <div className="flex flex-row items-center justify-between">
            <nav className="mb-0 order-1" role="navigation" aria-label="Navigation">
              <ul className="flex justify-center">
                <li className="first:ml-0">
                  <button onClick={() => previousPage()} className="text-xs btn bg-white border-slate-200 hover--border-slate-300 text-indigo-500 disabled:text-slate-300 disabled:cursor-not-allowed" disabled={!canPreviousPage}>&lt;- Previous</button>
                </li>
                <li className="ml-3">
                  <button onClick={() => nextPage()} className="text-xs btn bg-white border-slate-200 hover--border-slate-300 text-indigo-500 disabled:text-slate-300 disabled:cursor-not-allowed" disabled={!canNextPage}>Next -&gt;</button>
                </li>
              </ul>
            </nav>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <div className="text-xs text-slate-700 text-left">Loading...</div>
            ) : (
              <div className="text-xs text-slate-700 text-left">
                Showing{' '}
                <span className="font-semibold text-slate-700">{page.length * pageIndex + 1}</span>{' '}
                to{' '}
                <span className="font-semibold text-slate-700">{page.length * (pageIndex + 1)}</span>{' '}
                of{' '}
                <span className="font-semibold text-slate-700">{totalCount}</span>{' '}
                results
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
