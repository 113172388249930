import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Bar, Line } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";
import { useRealmApp } from "../RealmApp";
import { format } from "d3-format";
import { useMediaQuery } from "react-responsive";

export const background = "white";

export default function ValueBox({ arg, isPortals = false }) {
  const app = useRealmApp();

  const [data, setData] = useState(0);

  useEffect(() => {
    async function fetchAvg() {
      let response = await app.currentUser.functions.axie_test(arg);
      const respData = response.result;
      setData(respData[respData.length - 1].value);
    }

    async function fetchAvgPortals() {
      let response = await app.currentUser.functions.portals_test(arg);
      const respData = response.result;
      setData(respData[respData.length - 1].value);
    }

    if (isPortals) {
      fetchAvgPortals();
    } else {
      fetchAvg();
    }
  }, [app.currentUser.functions, arg, isPortals]);

  const value = format("$,")(data);

  // return a box with the value centered inside
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontFamily: "IBM Plex Sans",
            fontSize: "1.3rem",
            color: "#1e1870",
            textAnchor: "middle",
            // bold
            fontWeight: 400,
          }}
        >
          {value}
        </p>
        <p
          style={{
            fontFamily: "IBM Plex Sans",
            fontSize: "0.65rem",
            color: "#1e1870",
            textAnchor: "middle",
            fontWeight: 400,
          }}
        >
          {isPortals ? "Portals".toUpperCase() : "Axie Infinity".toUpperCase()}
        </p>
      </div>
    </div>
  );
}
