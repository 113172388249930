import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Line, Circle } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleLinear, scaleTime } from "@visx/scale";
import { timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { extent, bisector } from "d3-array";
import { useRealmApp } from "../RealmApp";
import { format } from "d3-format";
import { useMediaQuery } from "react-responsive";

export const background = "white";

const defaultMargin = { top: 20, left: 70, right: 30, bottom: 60 };

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "#FFECE8",
  color: "black",
};

const axisLabelProps = {
  fontFamily: "IBM Plex Sans",
  fontSize: 12,
  color: "black",
  textAnchor: "middle",
};

/* const dataLabelStyle = {
  fontFamily: "IBM Plex Sans",
  fontSize: 11,
  color: "black",
}; */

/*
const parseDate = timeParse("%Y-%m-%d");
const format = timeFormat("%b %d");
const formatDate = (date) => format(parseDate(date)); */

const formatDate = (type) => (timestamp) => {
  //const formatTime = timeFormat();
  //const day = timeFormat("%d");

  /* const ordinalSuffixes = ["th", "st", "nd", "rd"];
  function ordinalSuffix(number) {
    const value = number % 100;

    return (
      ordinalSuffixes[(value - 20) % 10] ||
      ordinalSuffixes[value] ||
      ordinalSuffixes[0]
    );
  } */
  /* if (type === "day") {
    const day = timeFormat("%d");
    return formatTime(date) + ordinalSuffix(+day(date));
  } */

  const dateFormats = {
    month: "%y-%m",
    week: "%y-%m-%d",
    day: "%b %d",
    fullDate: "%Y-%m-%d",
    hour: "%b %d %H:%M",
  };

  const date = new Date(timestamp);
  const formatTime = timeFormat(dateFormats[type]);

  return formatTime(date);
};

export default function ScatterPlot({
  width,
  height,
  events = false,
  margin = defaultMargin,
  isPortals = false,
  arg,
}) {
  const app = useRealmApp();

  const [data, setData] = useState([]);

  const [xNumTicks, setXNumTicks] = useState(5);

  useEffect(() => {
    const isMd = width > 650;
    const isXl = width > 850;
    const is2xl = width > 1000;
    const is3xl = width > 1200;
    let numTicks = is3xl ? 19 : is2xl ? 17 : isXl ? 13 : isMd ? 9 : 6;
    setXNumTicks(numTicks);
  }, [width]);

  useEffect(() => {
    async function fetchAvg() {
      let response = await app.currentUser.functions.axie_test(arg);
      setData(response.result);
    }
    async function fetchAvgPortals() {
      let response = await app.currentUser.functions.portals_test(arg);
      setData(response.result);
    }

    if (isPortals) {
      fetchAvgPortals();
    } else {
      fetchAvg();
    }
  }, [app.currentUser.functions, arg, isPortals]);

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal(
    {
      scroll: true,
      detectBounds: true,
    }
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip({
    // initial tooltip state
    tooltipOpen: false,
    tooltipLeft: 0,
    tooltipTop: 0,
    tooltipData: "",
  });

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // accessors
  const getTimestamp = (d) => d.timestamp;

  const getPriceperUnit = (d) => Number(d.price_per_unit);

  const yNumTicks = 3;

  //const isSm = useMediaQuery({ query: '(min-width: 640px)' })
  // const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  // const isXl = useMediaQuery({ query: "(min-width: 1280px)" });
  // const is2xl = useMediaQuery({ query: "(min-width: 1536px)" });

  // const xNumTicks = is2xl ? 18 : isXl ? 13 : isMd ? 9 : 7;

  const metaData = useMemo(() => {
    // This could be implemented better, maybe with changing values per inside keys
    const metaDataKeys = {
      16: {
        accessors: { x: getTimestamp, y: getPriceperUnit },
        yAxisLabelText: "Price per Unit",
        xAxisLabelText: "Date",
        dataLabelFormat: "$,",
        yAxisFormat: "~s",
        xAxisFormat: formatDate("day"),
        tooltipLabelFormat: formatDate("hour"),
        tooltipLabel: "Price per Unit",
      },
    };

    return metaDataKeys[arg];
  }, [arg]);

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleTime({
        domain: extent(data, metaData.accessors.x),
        range: [0, xMax],
      }),
    [metaData.accessors.x, data, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(metaData.accessors.y))],
      }),
    [metaData.accessors.y, data, yMax]
  );

  const handleTooltip = useCallback(
    (event) => {
      let { x } = localPoint(event) || { x: defaultMargin.left };
      // idk why I needed to do this. above line was like this || { x: defaultMargin.left };
      x = x - defaultMargin.left;
      const bisect = bisector(metaData.accessors.x).left;

      const nearestValue = xScale.invert(x);
      const nearestValueIndex = bisect(data, nearestValue, 1);
      const d0 = data[nearestValueIndex - 1];
      const d1 = data[nearestValueIndex];
      let nearestDatum = d0;
      if (d1 && metaData.accessors.x(d1)) {
        nearestDatum =
          nearestValue.valueOf() - metaData.accessors.x(d0).valueOf() >
          metaData.accessors.x(d1).valueOf() - nearestValue.valueOf()
            ? d1
            : d0;
      }

      const containerX =
        ("clientX" in event ? event.clientX : 0) - containerBounds.left;
      const containerY =
        ("clientY" in event ? event.clientY : 0) - containerBounds.top;

      showTooltip({
        tooltipData: nearestDatum,
        // if this value is x, it will mirror the pointer event position
        // by finding the location of the nearest data point,
        // it appears to "snap" to nearest point
        tooltipLeft: containerX, //xScale(getLetter(nearestDatum)),
        tooltipTop: containerY, //yScale(getLetterFrequency(nearestDatum))
      });
    },
    [metaData.accessors, containerBounds, data, showTooltip, xScale]
  );

  return width < 10 ? null : (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            scale={yScale}
            width={xMax}
            stroke="#f2f2f2"
            shapeRendering="crispEdges"
            columns={false}
            //key={`grid-${'animationTrajectory'}`} // force animate on update
            //animationTrajectory={animationTrajectory}
            numTicks={yNumTicks}
            strokeOpacity={1}
          />
          {/* Bottom Axis Line */}
          <Line
            from={{ x: 0, y: yMax }}
            to={{ x: xMax, y: yMax }}
            stroke="black"
            shapeRendering="crispEdges"
            strokeWidth={1}
            pointerEvents="none"
          />
          <AxisBottom
            top={yMax}
            scale={xScale}
            label={metaData.xAxisLabelText}
            tickFormat={metaData.xAxisFormat}
            stroke={"black"}
            strokeWidth={1}
            numTicks={xNumTicks}
            hideTicks
            hideAxisLine
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            tickLabelProps={() => ({ ...axisLabelProps, fontSize: 10 })}
          />
          <AxisLeft
            numTicks={yNumTicks}
            scale={yScale}
            label={metaData.yAxisLabelText}
            tickFormat={(i) => format(metaData.yAxisFormat)(i)}
            stroke={"black"}
            labelProps={{ ...axisLabelProps, fontWeight: "500" }}
            hideTicks
            hideAxisLine
            tickLabelProps={() => axisLabelProps}
          />
          <Group pointerEvents="none">
            {data.map((d, i) => (
              <Circle
                key={i}
                cx={xScale(metaData.accessors.x(d))}
                cy={yScale(metaData.accessors.y(d))}
                r={5 /*rScale(radius(point))*/}
                fill={"#005ADC"}
                fillOpacity={0.8}
              />
            ))}
          </Group>
          <rect
            onPointerMove={handleTooltip}
            onMouseLeave={() => {
              hideTooltip();
            }}
            width={xMax}
            height={yMax}
            fill="transparent"
          />
          {tooltipData && (
            <g>
              {/* Crosshair lines */}
              <Line
                from={{ x: tooltipLeft - margin.left, y: 0 }}
                to={{ x: tooltipLeft - margin.left, y: yMax }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
              <Line
                from={{ x: 0, y: tooltipTop - margin.top }}
                to={{ x: xMax, y: tooltipTop - margin.top }}
                stroke="rgba(0,0,0,.2)"
                shapeRendering="crispEdges"
                strokeWidth={1}
                pointerEvents="none"
              />
            </g>
          )}
        </Group>
      </svg>

      {tooltipOpen && tooltipData && (
        <>
          <TooltipInPortal
            key={Math.random()} // needed for bounds to update correctly
            left={tooltipLeft}
            top={tooltipTop}
            style={tooltipStyles}
          >
            <div
              className="py-1.5 px-2"
              style={{
                fontFamily: "IBM Plex Sans",
                fontSize: 13,
                color: "black",
              }}
            >
              {/** date */}
              <h1
                style={{
                  fontWeight: "500",
                }}
              >
                {metaData.tooltipLabelFormat(metaData.accessors.x(tooltipData))}
              </h1>

              <ul className="first:mt-4">
                <li className="min-w-[14rem] grid grid-cols-[auto_auto_1fr_auto] gap-2 mt-2 text-right">
                  <span>
                    <svg
                      stroke={"#005ADC"}
                      fill={"#005ADC"}
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      icon="circle-fill"
                      aria-hidden="true"
                      alt="Bar Color"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8"></circle>
                    </svg>
                  </span>
                  <span>{metaData.tooltipLabel}</span>
                  <span>
                    {/* Value */}
                    {format(metaData.dataLabelFormat)(
                      metaData.accessors.y(tooltipData)
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </TooltipInPortal>
        </>
      )}
    </>
  );
}
