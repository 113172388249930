import React from "react";

export const Footer = () => {
    return(
        <>
        <div className="container">
        <div className="legal-note-container"><div>© Omnimeta Ltd, a company registered in the UK (13887510) with registered address 20-22 Wenlock Road, London N1 7GU.</div></div>
        </div>
        </>
    )
}