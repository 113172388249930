import React from "react";

const DuneAllWorldsEmbed = ({ src, isTable, title, subtitle, width }) => {
  const heightByTitle = {
    "TODAY'S TOP METAVERSE LAND TRANSACTIONS": 900,
  };

  const heightBySubTitle = {
    "Land market cap; Ethereum-based worlds": 900,
  };

  let height = width === 2 ? 200 : 350;

  if (title in heightByTitle) {
    height = heightByTitle[title];
  }

  if (subtitle in heightBySubTitle) {
    height = heightBySubTitle[subtitle];
  }

  return (
    <>
      <div
        className={`flex flex-col col-span-full sm:col-span-${width} bg-white shadow-lg rounded-sm border border-gray-200`}
      >
        <iframe
          title={title}
          src={src}
          height={height}
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
        ></iframe>
      </div>
    </>
  );
};

export default DuneAllWorldsEmbed;
