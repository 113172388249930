import React, { useState } from "react";
//import Table from "../../charts/Table";
import TableNew from "../../charts/TableNew";
import { useRealmApp } from "../../RealmApp";

function TableWrapper3() {
  const app = useRealmApp();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [sort, setSort] = React.useState({
    sortDirection: -1,
    accessor: "total",
  });
  const [selectedType, setSelectedType] = useState(7);

  const columnHeaderClick = (column) => {
    // this might be better if we update state by prev val.
    if (sort.accessor === column.id) {
      setSort({
        sortDirection: sort.sortDirection * -1,
        accessor: column.id,
      });
    } else {
      setSort({
        sortDirection: -1,
        accessor: column.id,
      });
    }
  };

  const truncate = (val) =>
    val.length >= 15 ? val.slice(0, 6) + "..." + val.slice(-4) : val;
  const format_tw = (val) => (val[0] === "@" ? val.slice(1) : val);

  const columns = React.useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US");
    return [
      {
        Header: "MetaMetriks Profile",
        accessor: "mm_name",
        className: "text-red-800",
        style: {
          color: "rgb(159,18,57)",
        },
        Cell: ({ row, value }) => (
          <div className="flex flex-row justify-center align-center font-semibold text-red-800">
            {/* if there is no mm name, return opensea name */}
            {value != null
              ? value
              : ![null, undefined, "Unnamed"].includes(
                  row.original.opensea_name
                )
              ? truncate(row.original.opensea_name)
              : row.original.opensea_twitter_username != null
              ? truncate(row.original.opensea_twitter_username)
              : "-"}
          </div>
        ),
      },
      {
        Header: "Profile Type",
        accessor: "owner_type",
        Cell: ({ row, value }) => (
          <div className="flex flex-row justify-center align-center">
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: "Wallet(s)",

        accessor: "address",
        Cell: ({ row, value, test }) =>
          row.original.wallets ? (
            row.original.wallets.map((v, i, arr) => (
              <a
                href={`https://etherscan.io/address/${v.address}`}
                target="_blank"
                rel="noreferrer"
                title={v.address}
                className="text-sky-500 visited:text-fuchsia-700"
              >
                {v.ens_name != null
                  ? truncate(v.ens_name)
                  : truncate(v.address)}
                {/* if it's not the last item, add a comma */}
                {i !== arr.length - 1 ? ", " : ""}
              </a>
            ))
          ) : (
            <a
              href={`https://etherscan.io/address/${value}`}
              target="_blank"
              rel="noreferrer"
              title={value}
              className="text-sky-500 visited:text-fuchsia-700"
            >
              {row.original.ens_name != null
                ? truncate(row.original.ens_name)
                : truncate(value)}
            </a>
          ),
      },
      {
        Header: "OpenSea",
        accessor: "opensea_name",
        Cell: ({ row, value }) =>
          row.original.wallets ? (
            row.original.wallets.map((v, i, arr) => (
              <div className="flex flex-row justify-center align-center">
                <a
                  href={`https://opensea.io/${v.address}`}
                  target="_blank"
                  rel="noreferrer"
                  title={v.opensea_name}
                  className={`text-sky-500 visited:text-fuchsia-700 ${
                    v.opensea_verified ? "ml-4" : ""
                  }`}
                >
                  {v.opensea_name == null
                    ? "Unnamed"
                    : truncate(v.opensea_name)}
                  {/* if it's not the last item, add a comma */}
                  {i !== arr.length - 1 ? ", " : ""}
                </a>
                {v.opensea_verified ? (
                  <svg
                    className="mt-[3px] ml-1 w-4 h-4 fill-current text-sky-500"
                    viewBox="0 0 16 16"
                  >
                    <title>Verified by OpenSea</title>
                    <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"></path>
                  </svg>
                ) : null}
              </div>
            ))
          ) : (
            <div className="flex flex-row justify-center align-center">
              <a
                href={`https://opensea.io/${row.original.address}`}
                target="_blank"
                rel="noreferrer"
                title={value}
                className={`text-sky-500 visited:text-fuchsia-700 ${
                  row.original.opensea_verified ? "ml-4" : ""
                }`}
              >
                {value == null ? "Unnamed" : truncate(value)}
              </a>
              {row.original.opensea_verified ? (
                <svg
                  className="mt-[3px] ml-1 w-4 h-4 fill-current text-sky-500"
                  viewBox="0 0 16 16"
                >
                  <title>Verified by OpenSea</title>
                  <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"></path>
                </svg>
              ) : null}
            </div>
          ),
      },
      /* {
        Header: "Sandbox name",
        accessor: "sandbox_username",
        Cell: ({ row, value }) => (
          <>
            {value != null ? (
              <a
                href={`https://www.sandbox.game/users/${value.toLowerCase()}/${
                  row.original.sandbox_id
                }/`}
                target="_blank"
                rel="noreferrer"
                title={value}
                className="text-sky-500 visited:text-fuchsia-700"
              >
                {value === null ? "Unnamed" : truncate(value)}
              </a>
            ) : (
              <div>-</div>
            )}
          </>
        ),
      }, */
      /* {
        Header: "Decentraland name",
        accessor: "dcl_name",
        Cell: ({ row, value }) => (
          <>
            {value != null ? (
              <a
                href={`https://market.decentraland.org/accounts/${row.original.address}/`}
                target="_blank"
                rel="noreferrer"
                title={value}
                className="text-sky-500 visited:text-fuchsia-700"
              >
                {value === null ? "Unnamed" : truncate(value)}
              </a>
            ) : (
              <div>-</div>
            )}
          </>
        ),
      }, */
      {
        Header: "Twitter",
        accessor: "opensea_twitter_username",
        Cell: ({ row, value }) => {
          // we're not using sandbox twitter usernames
          // for merged wallets yet.
          if (row.original.wallets) {
            let twitterUsernames = row.original.wallets.map(
              (i) => i.opensea_twitter_username
            );
            // remove duplicates
            twitterUsernames = twitterUsernames.filter(
              (v, i, a) => a.indexOf(v) === i
            );
            // remove nulls
            twitterUsernames = twitterUsernames.filter((i) => i != null);
            // if twitterUsernames empty, add a null value
            if (twitterUsernames.length === 0) {
              twitterUsernames.push(null);
            }

            return twitterUsernames.map((v, i, arr) => (
              <div className="flex flex-row justify-center align-center">
                {v != null ? (
                  <a
                    href={`https://twitter.com/${format_tw(v)}`}
                    target="_blank"
                    rel="noreferrer"
                    title={v}
                    className="text-sky-500 visited:text-fuchsia-700"
                  >
                    {truncate("@" + format_tw(v))}
                  </a>
                ) : (
                  <div>-</div>
                )}
              </div>
            ));
          }

          // null null value/null
          // notnull  ~ value
          // null notnull notnull
          const sand_twitter = row.original.sandbox_twitter_username;
          if (value === null && sand_twitter !== null) {
            value = sand_twitter;
          }
          return (
            <>
              {value != null ? (
                <a
                  href={`https://twitter.com/${format_tw(value)}`}
                  target="_blank"
                  rel="noreferrer"
                  title={value}
                  className="text-sky-500 visited:text-fuchsia-700"
                >
                  {truncate("@" + format_tw(value))}
                </a>
              ) : (
                <div>-</div>
              )}
            </>
          );
        },
      },
      /* {
        Header: "Instagram",
        accessor: "opensea_instagram_username",
        Cell: ({ row, value }) => {
          return (
            <>
              {value != null ? (
                <a
                  href={`https://instagram.com/${format_tw(value)}`}
                  target="_blank"
                  rel="noreferrer"
                  title={value}
                  className="text-sky-500 visited:text-fuchsia-700"
                >
                  {truncate("@" + format_tw(value))}
                </a>
              ) : (
                <div>-</div>
              )}
            </>
          );
        },
      }, */
      /* {
        Header: "Website",
        accessor: "opensea_website",
        Cell: ({ row, value }) => {
          return (
            <>
              {value != null ? (
                <a
                  href={value}
                  target="_blank"
                  rel="noreferrer"
                  title={value}
                  className="text-sky-500 visited:text-fuchsia-700"
                >
                  {truncate(value.replace("https://", "").replace("www.", ""))}
                </a>
              ) : (
                <div>-</div>
              )}
            </>
          );
        },
      }, */
      {
        Header: "TOTAL UNITS",
        accessor: "total",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Decentraland Land",
        accessor: "decentraland",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Decentraland Estate",
        accessor: "decentralandEstate",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Sandbox",
        accessor: "sandbox",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "NFT Worlds",
        accessor: "nftWorlds",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Otherside",
        accessor: "otherside",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Arcade Land",
        accessor: "arcadeLand",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Somnium Space",
        accessor: "somniumSpace",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Somnium Worlds",
        accessor: "somniumWorlds",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Metroverse",
        accessor: "metroverse",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "BYO Land",
        accessor: "byoLand",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Worldwide Webb",
        accessor: "worldwideWebb",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Treeverse",
        accessor: "treeverse",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "FLUF World - Burrows",
        accessor: "flufWorld",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Farmland",
        accessor: "farmLand",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Netvrk",
        accessor: "netvrk",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Wilder World",
        accessor: "wilderWorld",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Mavia Land",
        accessor: "maviaLand",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Polka City",
        accessor: "polkaCity",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Influence Asteroids",
        accessor: "influenceAsteroids",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Etherland",
        accessor: "etherland",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Ethereum Towers",
        accessor: "ethereumTowers",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "OVR",
        accessor: "ovr",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Matrix World",
        accessor: "matrixWorld",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "League Of Kingdoms",
        accessor: "leagueOfKingdoms",
        Cell: ({ value }) => String(formatter.format(value)),
      },
    ];
  }, []);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      const typesDict = {
        0: "Collector",
        1: "Community",
        2: "Corporation",
        3: "Developer",
        4: "Investor",
        5: "Lender",
        6: "Platform Partner",
        7: "All Profile Types",
      };

      // This will get called when the table needs new data
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      async function fetchAPI() {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          let response = await app.currentUser.functions.getOwnersNew(
            pageIndex,
            pageSize,
            sort.accessor,
            sort.sortDirection,
            typesDict[selectedType]
          );

          let data = response["result"][0];

          setPageCount(Math.ceil(data.metadata.total / pageSize));
          setTotalCount(data.metadata.total);

          data = data.data;
          //console.log(data)
          /* data = data.map(i => {
          return {
            address: i.address,
            opensea_name: i.opensea_name,
            opensea_verified: i.opensea_verified,
            byoLand: i.byoLand,
            decentraland: i.decentraland,
            decentralandEstate: i.decentralandEstate,
            metroverse: i.metroverse,
            ...
            total: i.total,
          }
        }) */
          setData(data);
          setLoading(false);
        }
      }

      fetchAPI();
    },
    [app.currentUser.functions, selectedType, sort.accessor, sort.sortDirection]
  );

  return (
    <TableNew
      title="OWNERSHIP: aggregated across worlds"
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      tablePageSize={20}
      totalCount={totalCount}
      pageCount={pageCount}
      onHeaderClick={columnHeaderClick}
      getHeaderProps={(headerInfo) => {
        return headerInfo.Header === "Owner Name"
          ? {
              style: {
                color: "rgb(159,18,57)",
              },
            }
          : {};
      }}
      selectedType={selectedType}
      setSelectedType={setSelectedType}
    />
  );
}

export default TableWrapper3;
