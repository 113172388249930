import React from "react";
import Table from "../../charts/Table";
import { useRealmApp } from "../../RealmApp";


function AxieTransactionsTableWrapper({ arg, isPortals }) {
  const argsToTitle = {
    17: "TRANSACTIONS: top transactions by value (P24h)",
    18: "TRANSACTIONS: top transactions by value (P30d)",
    19: "TRANSACTIONS: all transactions (all time)",
  };

  const app = useRealmApp();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const truncate = (val) =>
    val.length >= 15 ? val.slice(0, 6) + "..." + val.slice(-4) : val;

  const columns = React.useMemo(
    () => {
      const formatter = new Intl.NumberFormat("en-US");
      const priceFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    
      return [
      {
        Header: "Buyer",
        accessor: "buyer",
        Cell: ({ row, value }) => (
          <a
            href={isPortals ? `https://solscan.io/account/${value}` : `https://explorer.roninchain.com/address/${value.replace('0x', 'ronin:')}`}
            target="_blank"
            rel="noreferrer"
            title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {row.original.ens_name != null
              ? row.original.ens_name
              : truncate(value)}
          </a>
        ),
      },
      {
        Header: "Seller",
        accessor: "seller",
        Cell: ({ row, value }) => (
          <a
            href={isPortals ? `https://solscan.io/account/${value}` : `https://explorer.roninchain.com/address/${value.replace('0x', 'ronin:')}`}
            target="_blank"
            rel="noreferrer"
            title={value}
            className="text-sky-500 visited:text-fuchsia-700"
          >
            {row.original.ens_name != null
              ? row.original.ens_name
              : truncate(value)}
          </a>
        ),
      },
      {
        Header: "Price Paid",
        accessor: "tx_price",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
      {
        Header: "Price per Unit",
        accessor: "tx_price_per_unit",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
      {
        Header: "Current Price",
        accessor: "current_price",
        Cell: ({ row, value }) => priceFormatter.format(value),
      },
      {
        Header: "Current per Unit",
        accessor: "current_price_per_unit",
        Cell: ({ row, value }) => priceFormatter.format(value),
        /* Cell: ({ row, value }) => (
            {row.original.ens_name != null ? row.original.ens_name : truncate(value)}
        ) */
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row, value }) => (
          <div className="whitespace-nowrap">{value}</div>
        ),
      },
      {
        Header: "Units",
        accessor: "nft_count",
        /* Cell: ({ row, value }) => (
            {row.original.ens_name != null ? row.original.ens_name : truncate(value)}
        ) */
      },
      {
        Header: "Currency",
        accessor: "currency",
        Cell: ({ row, value }) => {
          return isPortals ? "SOL" : "WETH";
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => String(formatter.format(value)),
      },
      {
        Header: "Platform",
        accessor: "platform",
        Cell: ({ row, value }) => (
          <div className="whitespace-nowrap">{isPortals ? value : "Axie Marketplace"}</div>
        ),
      },
      {
        Header: () => <div className="text-left">Token</div>,
        accessor: "token_ids",
        Cell: ({ row, value }) => (
          <div className="whitespace-nowrap text-left">
            {value.map((i, idx, list) => (
              <a
                href={isPortals ? `https://solscan.io/token/${value}` : `https://explorer.roninchain.com/token/ronin:8c811e3c958e190f5ec15fb376533a3398620500/${i}`}
                target="_blank"
                rel="noreferrer"
                title={i}
                className="text-sky-500 visited:text-fuchsia-700"
              >
                {idx + 1 !== list.length ? truncate(i) + ", " : truncate(i)}
              </a>
            )
            )
            }
          </div>
        ),
      },
    ]},
    []
  );

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    async function fetchAPI() {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        let response;

        if (isPortals) {
          response = await app.currentUser.functions.portals_test(
            arg,
            pageIndex,
            pageSize
          );
        } else {
          response = await app.currentUser.functions.axie_test(
            arg,
            pageIndex,
            pageSize
          );
        }

        let result = response["result"]
        if (result.length === 0) {
          result.push({
            data: [],
            metadata: {
              total: 0
            }
          })
        }
        let data = result[0];

        setPageCount(Math.ceil(data.metadata.total / pageSize));
        setTotalCount(data.metadata.total);

        data = data.data;

        setData(data);
        setLoading(false);
      }
    }

    fetchAPI();
  }, [app.currentUser.functions, arg, isPortals]);

  return (
    <Table
      title={argsToTitle[arg]}
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      tablePageSize={10}
      totalCount={totalCount}
    />
  );
}

export default AxieTransactionsTableWrapper;
