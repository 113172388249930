const DCLPriceHeatmapCustomTooltip = ({ cell }, dimLen, isAvgTab) => {
  if (cell.color === "#1E1C32") return null;
  const y = cell.serieId;
  const x = cell.data.x;

  const sectorLen = 1; //408 / dimLen;

  var x_division_len = 314 / dimLen;
  var y_division_len = 309 / dimLen;

  const getRangeX = (value) => {
    //??? not sure if these are correct
    if (dimLen === 314) {
      const start = value - 151;
      const end = value - 151;

      return start === end ? start : `${start} to ${end}`;
    }
    // msla - 150
    // 12345 diye gidiyo soldan saga value

    const start = Math.ceil(-150 + (value - 1) * x_division_len);
    const end = Math.ceil(-150 + value * x_division_len);
    return start === end ? start : `${start} to ${end}`;

    /*     for i in range(divide_by 150 mesela):
        x_start = -150 + (i * x_division_len)  # including
        x_end = -150 + ((i + 1) * x_division_len)
        x_divisions.append([x_start, x_end])

        y_start = -150 + (i * y_division_len)  # including
        y_end = -150 + ((i + 1) * y_division_len)
        y_divisions.append([y_start, y_end]) */
  };

  const getRangeY = (value) => {
    //??? not sure if these are correct
    if (dimLen === 314) {
      const start = value - 151;
      const end = value - 151;

      return start === end ? start : `${start} to ${end}`;
    }
    const start = Math.ceil(-150 + (value - 1) * y_division_len);
    const end = Math.ceil(-150 + value * y_division_len);
    return start === end ? start : `${start} to ${end}`;
    // 12345 diye gidio asagidan yukariya
  };

  return (
    cell.label && (
      <div className="bg-white rounded shadow py-2 px-3 flex flex-col">
        <div className="mb-1">
          <span className="font-bold">
            {isAvgTab ? "Avg Price: " : "Transaction Count: "}
          </span>
          <span className="font-normal">{cell.label}</span>
        </div>
        <div>
          <span className="font-bold text-sm">x: </span>
          <span className="font-normal text-sm">{getRangeX(x)}</span>
        </div>
        <div>
          <span className="font-bold text-sm">y: </span>
          <span className="font-normal text-sm">{getRangeY(y)}</span>
        </div>
      </div>
    )
  );
};

export default DCLPriceHeatmapCustomTooltip;

/* 
      <span style="display: block; width: 12px; height: 12px; background: rgb(248, 140, 82); margin-right: 7px;">
      </span>

    <div style="background: white; color: inherit; font-size: inherit; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px; padding: 5px 9px;">
    <div style="white-space: pre; display: flex; align-items: center;">
    </div>
    </div>

    */
