import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { rotateData } from "../Constants/RotateData";

export const Rotate = () => {
  return (
    <div>
      <div className="carousel-container-2">
        <div className="ratio-carousel">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            statusFormatter={false}
            stopOnHover={false}
            showArrows={false}
          >
            {rotateData.map((x) => {
              return (
                <div key={x.id}>
                  <img src={x.imgSrc} alt="legend1" />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="btn-center">
        <button>
          <a className="text-none" href="https://www.patreon.com/metametriks">
            Subscribe to Pro today for $199
          </a>
        </button>
      </div>
    </div>
  );
};
