import React from "react";

const SearchBar = ({ searchValue, setSearchValue }) => {
  return (
    <form className=" w-56 mt-2">
      <div className="relative">
        <input
          id="form-search"
          className="border-slate-200 px-3 py-2 rounded pl-9 w-full text-sm"
          type="search"
          placeholder="Search name/coordinates"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          value={searchValue}
        />
        <button
          className="inset-0 right-auto absolute kp"
          type="submit"
          aria-label="Search"
          disabled
        >
          <svg
            className="w-4 h-4 shrink-0 text-slate-400 ml-3 mr-2 fill-current"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"></path>
            <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"></path>
          </svg>
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
