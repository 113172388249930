export const opportunityData = [
  {
    id: 1,
    text:
      "Compare key metrics for over 30 virtual worlds including Sandbox, Decentraland, NFT Worlds, Metroverse, Worldwide Webb, Axie Infinity, Cryptovoxels, Somnium Space, BYO Land, Fluf World, Netvrk, SuperWorld & many more",
  },
  {
    id: 2,
    text:
      "30+ measures for each world including land price (by day, week & month), unit sales, value, all transactions & ownership - including portfolio valuation, top owners, sentiment & accumulation/disposal",
  },
  {
    id: 3,
    text:
      "Off-chain metadata incorporated: see how land price is affected by size, location, district, adjacencies, waterfront/roadside, distance from Plaza & other key traits",
  },
  {
    id: 4,
    text:
      "View traffic, engagement & visitor information for individual locations",
  },
  {
    id: 5,
    text:
      "Measure category size & growth of a range of other metaverse assets (wearables, vehicles, avatars, access passes etc)",
  },
  {
    id: 6,
    text:
      "Access to proprietary tools: price heatmaps to identify hot & cold regions, enhanced wallet-labelling (profile of each owner beyond wallet address) & more",
  },
  
];
