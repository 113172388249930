import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
//import DuneEmbed from "../partials/DuneEmbed";
import TableWrapper2 from "../partials/wrappers/TableWrapper2";
import DuneBaronsEmbed from "../partials/DuneBaronsEmbed";

function Barons({ data }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const widths = {
      0: 6,
      1: 4,
      2: 2
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Cards */}
            <div className="grid grid-cols-12 gap-3 items-center">
              {/* Dune embeds */}
              {data.map((i, idx) => {
                if (i.title === "top_owners_new") return <TableWrapper2 />

                return (
                  <DuneBaronsEmbed
                    key={i.title}
                    title={i.title}
                    isTable={i.is_table}
                    src={i.embed_url}
                    width={widths[idx % 3]}
                  />
                )
              }
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Barons;
